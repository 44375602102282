<template>
  <v-text-field
    v-model="formatedNumber"
    :label="label"
    :disabled="disabled"
    :rules="rules"
    :step="step"
    :placeholder="placeholder || '0'"
    required
    hide-details
    outlined
    dense
    data-test="textInput"
    :error="error"
    :readonly="readonly"
    @change="changeNumber"
    @blur="$emit('blur')"
  />
</template>

<script>
import _ from "lodash";

export default {
  props: [
    "value",
    "scale",
    "required",
    "label",
    "rules",
    "placeholder",
    "disabled",
    "error",
    "availableZero",
    "readonly",
  ],
  data() {
    return {
      number: 0,
      formatedNumber: 0,
    };
  },
  computed: {
    step() {
      if (this.scale > 0) {
        return _.floor(Math.pow(0.1, this.scale), this.scale);
      }
      if (this.scale === 0) {
        return 1;
      }
    },
  },
  watch: {
    value: {
      handler(val) {
        if (_.isFinite(val)) {
          this.number = val;
          this.formatedNumber =
            val === 0
              ? this.availableZero
                ? "0"
                : ""
              : this.$options.filters.separateDigits(
                this.number.toFixed(this.scale).replace(".", ","),
              );
        } else if (!val) {
          this.number = 0;
          this.formatedNumber = "";
        }
      },
      immediate: true,
    },
  },
  mounted() {
    const input = this.$children[0].$refs.input;
    if (!input) {
      return;
    }
    input.addEventListener("input", () => {
      const replaceRegex = /[^0-9,]/;
      let formattedValue = input.value;
      let count = 0;
      for (let i = 0; i < formattedValue.length; i++) {
        if (formattedValue[i] === "," && count === 1) {
          const arrayValues = formattedValue.split("");
          arrayValues.splice(i, 1);
          formattedValue = arrayValues.join("");
        } else if (formattedValue[i] === "," && count < 1) {
          count += 1;
        }
      }
      formattedValue = formattedValue.replace(replaceRegex, "");

      let position = input.selectionStart;
      if (input.value.length !== formattedValue.length) {
        position--;
      }
      input.value = formattedValue;
      if (position) {
        setCaretPosition(input, position);
      }
      input.value = formattedValue;

      this.$children[0].$data.lazyValue = formattedValue;
      this.$children[0].$emit("input", formattedValue);

      function setCaretPosition(el, caretPos) {
        if (el != null) {
          if (el.createTextRange) {
            var range = el.createTextRange();
            range.move("character", caretPos);
            range.select();
          } else {
            if (el.selectionStart) {
              el.focus();
              el.setSelectionRange(caretPos, caretPos);
            } else el.focus();
          }
        }
      }
    });
  },
  methods: {
    changeNumber() {
      const number = this.formatedNumber.replace(/\s/g, "").replace(",", ".");
      const pattern = /^-?[0-9]*.{1}[0-9]*$/g;

      if (pattern.test(number)) {
        this.number = _.floor(+number, this.scale);
        this.formatedNumber = this.number.toFixed(this.scale);
      } else {
        this.number = 0;
        this.formatedNumber = "";
      }
      this.$emit("input", this.number);
    },
  },
};
</script>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
