import * as enumsApi from "../../backend/enumsApi.js";

const resourceMap = {
  "need-ex-types": "needEx",
  "packages-types": "packagesTypes",
  "delivery-types": "deliveryTypes",
  "contact-person-roles": "contactPersonRoles",
  "employee-roles": "employeeRoles",
  "shipping-states": "shippingStates",
};

const state = {
  needEx: [],
  deliveryTypes: [],
  packagesTypes: [],
  contactPersonRoles: [],
  employeeRoles: [],
  shippingStates: [],
};

const getters = {
  items: state => resource => {
    return state[resourceMap[resource]];
  },
};

const actions = {
  async getItems({ getters, commit }, resource) {
    if (getters.items(resource).length) {
      return;
    }
    const items = await enumsApi.getItems(resource);
    commit("setItems", {
      items,
      resource,
    });
  },
};

const mutations = {
  setItems(state, { items, resource }) {
    state[resourceMap[resource]] = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
