import http from "./http.js";

async function fetchCountries(resource) {
  const response = await http.get(`isg-logistics/${resource}/references`);
  return response.data.map(i => {
    return {
      text: i.title,
      value: i.id,
      inputLanguage: i.inputLanguage,
    };
  });
}

async function getCountryReference(resource, id) {
  const response = await http.get(`isg-logistics/${resource}/references/${id}`);
  return {
    id: response.data.id,
    title: response.data.iitle,
    inputLanguage: response.data.inputLanguage,
  };
}

async function searchReferencies(resource, search) {
  const response = await http.get(
    `isg-logistics/${resource}/references?searchTerm=${search}`,
  );
  if (Array.isArray(response.data)) {
    return response.data.map(i => {
      return {
        text: i.title,
        value: i.id,
        inputLanguage: i.inputLanguage,
      };
    });
  } else {
    response.data = [];
    return response;
  }
}

async function getCountry(resource, id) {
  const response = await http.get(`isg-logistics/${resource}/${id === "0" ? "new" : id}`);
  return {
    id: response.data.id,
    title: response.data.title,
    prefix: response.data.prefix,
    isoCode: response.data.isoCode,
    inputLanguage: response.data.inputLanguage,
    isRemoved: response.data.isRemoved,
    isConfirmed: response.data.isConfirmed,
  };
}


async function createCountry(resource, entity) {
  const response = await http.post(`isg-logistics/${resource}`, entity);
  return response;
}


export {
  fetchCountries,
  getCountryReference,
  searchReferencies,
  getCountry,
  createCountry,
};

