<template>
  <v-autocomplete
    v-model="selected"
    :label="label"
    :items="availableItems"
    :rules="rules"
    hide-details
    :clearable="clearable"
    :disabled="disabled || readonly"
    outlined
    dense
    prepend-inner-icon="mdi-folder-outline"
    @change="changeReference"
  >
    <template
      v-slot:append-item
      :items="items"
    >
      <slot
        name="append-content"
        :items="items"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import * as referenceApi from "../backend/referenceApi.js";

export default {
  props: [
    "label",
    "value",
    "resource",
    "customItems",
    "rules",
    "constraints",
    "disableItems",
    "withoutSelected",
    "parentId",
    "parentFilter",
    "returnObject",
    "clearable",
    "withoutLengthLock",
    "disabled",
    "outline",
    "scrollValue",
    "id",
    "info",
  ],
  data() {
    return { items: [], selected: this.value };
  },
  computed: {
    availableItems() {
      if (!this.constraints) {
        return this.items;
      }
      if (this.constraints.length === 0) {
        return [];
      }
      return this.items.filter((i) => this.constraints.indexOf(i.value) === -1);
    },
    readonly() {
      if (!this.withoutLengthLock) {
        return (
          this.availableItems.length < 2 ||
          (this.parentFilter && !this.parentId)
        );
      }
      return this.parentFilter && !this.parentId;
    },
  },
  watch: {
    value(newValue) {
      if (this.returnObject) {
        this.selected = newValue === null ? null : newValue.id;
      } else {
        this.selected = newValue;
        if (newValue && this.items.length > 0) {
          const item = this.items.find(i => i.value === newValue.value);
          if (item) {
            this.items.forEach(i => {
              if (i.value === newValue.value) {
                i.text = newValue.text;
              }
            });
          } else {
            this.items.push({
              text: this.selected,
              value: this.selected,
            });
          }
        }
      }
    },
    parentId(newValue) {
      this.selected = null;
      if (newValue) {
        this.getData();
      }
    },
    scrollValue() {
      if (!this.scrollValue) {
        return;
      }
      closeActivList(this.$children[0]);

      function closeActivList(value) {
        if (value.isActive) {
          value.isActive = false;
        }
        if (value.$children) {
          value.$children.forEach((v) => closeActivList(v));
        }
      }
    },
  },
  created() {
    if (this.resource) {
      if (!this.parentFilter || (this.parentFilter && this.parentId)) {
        this.getData();
      }
    }
    if (this.customItems) {
      this.items = this.customItems;
      if (this.withoutSelected) {
        this.selected = null;
      } else if (!this.selected && typeof this.items[1].value === "boolean") {
        this.selected = this.items[1];
      } else if (this.selected === null && this.selected === undefined) {
        this.selected = this.items[0];
      }
    }
  },
  methods: {
    async getData() {
      const res = await referenceApi.getTinyReferenceList(
        this.resource,
        this.id,
        this.info,
      );
      const result = res.data.result || res.data;
      this.items = result.map((i) => {
        return {
          value: i.id || i.id === 0 ? i.id : i,
          text: i.title ? i.title : i,
          disabled:
            this.disableItems && this.lodash.includes(this.disableItems, i.id),
        };
      });
      if (!this.selected && this.availableItems.length === 1) {
        this.selected = this.availableItems[0].value;
        this.$emit("input", this.selected);
      }
    },
    changeReference() {
      if (this.returnObject) {
        return this.$emit(
          "input",
          this.selected === null || this.selected === undefined
            ? null
            : {
              id: this.selected,
              title: this.items.find((i) => i.value === this.selected).text,
            },
        );
      }
      return this.$emit("input", this.selected);
    },
  },
};
</script>
