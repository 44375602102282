import axios from "axios";
import auth from "../auth";

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

http.interceptors.request.use(
  config => {
    config.headers["Authorization"] = "Bearer " + auth.getToken();
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  res => {
    return res;
  },
  err => {
    if (err.response && typeof err.response.data === "string") {
      throw err;
    } else {
      return Promise.reject(err);
    }
  },
);

export default http;


