
<template>
  <v-autocomplete
    v-model="selected"
    :items="items"
    :label="label"
    :rules="rules"
    :disabled="disabled"
    item-text="title"
    item-value="id"
    return-object
    hide-details
    :clearable=" clearable === undefined ? true : clearable"
    outlined
    dense
    @change="changeEnum"
  />
</template>
<script>
export default {
  props: [
    "value",
    "label",
    "rules",
    "withoutSelected",
    "returnObject",
    "resource",
    "disableItems",
    "disabled",
    "clearable",
    "scrollValue",
  ],
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    items() {
      const items = this.$store.getters["enums/items"](this.resource);
      if (this.disableItems) {
        const disableItems = items.map((i) => {
          return {
            id: i.id,
            title: i.title,
            disabled:
              this.disableItems &&
              this.lodash.includes(this.disableItems, i.id),
          };
        });
        return disableItems;
      }
      return items;
    },
  },
  watch: {
    value(newValue) {
      this.selected = newValue;
    },
    scrollValue() {
      if (!this.scrollValue) {
        return;
      }
      closeActivList(this.$children[0]);

      function closeActivList(value) {
        if (value.isActive) {
          value.isActive = false;
        }
        if (value.$children) {
          value.$children.forEach((v) => closeActivList(v));
        }
      }
    },
  },
  async created() {
    await this.$store.dispatch("enums/getItems", this.resource);
    if (this.withoutSelected) {
      this.selected = null;
    }
  },
  methods: {
    changeEnum() {
      if (this.returnObject) {
        return this.$emit(
          "input",
          this.selected
            ? {
              id: this.selected.id,
              title: this.selected.title,
            }
            : null,
        );
      }
      if (typeof this.selected === "object" && !this.returnObject) {
        return this.$emit("input", this.selected.id);
      }
      return this.$emit("input", this.selected);
    },
  },
};
</script>