<template>
  <v-card :flat="isModal ? true : false">
    <v-card-title class="pb-0">
      <h1 class="headline">
        Редактирование Страны
      </h1>
    </v-card-title>
    <v-card-text class="pt-2">
      <v-form v-model="isValid">
        <v-layout column>
          <v-flex xs12>
            <v-layout row>
              <v-flex
                xs6
                class="mt-3"
              >
                <v-select
                  v-model="entity.inputLanguage"
                  :items="languageItems"
                  :rules="[v => !!v || '']"
                  label="Язык ввода*"
                  class="mx-2 mb-3"
                  outlined
                  dense
                  hide-details
                  data-test="language"
                  @input="entityChange"
                />
              </v-flex>
              <v-flex
                xs6
                class="mt-3"
              >
                <TitleTextField
                  v-model="entity.title"
                  :required="true"
                  :rules="[rules.required, rules.min]"
                  :input-language="entity.inputLanguage"
                  label="Наименование*"
                  class="mx-2 mb-3"
                  @input="entityChange"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex xs6>
                <v-text-field
                  v-model="entity.prefix"
                  class="mx-2"
                  :required="true"
                  :rules="[rules.prefixLength]"
                  label="Префикс*"
                  :mask="getMask('prefix')"
                  hide-details
                  autofocus
                  outlined
                  dense
                  @input="entityChange"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="entity.isoCode"
                  class="mx-2"
                  :rules="[rules.isoCodeLength]"
                  label="Код Страны alpha-3"
                  :mask="getMask('isoCodeAlpha3')"
                  hide-details
                  autofocus
                  outlined
                  dense
                  @input="entityChange"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout class="mt-3">
          <v-layout v-if="isConfirmed === false">
            <v-btn
              small
              color="primary"
              data-test="saveAndCloseButton"
              class="mt-3"
              @click="saveAndClose"
            >
              Подтвердить
            </v-btn>
            <v-btn
              small
              data-test="cancelButton"
              class="mt-3"
              @click="cancel"
            >
              Отмена
            </v-btn>
            <v-spacer />
          </v-layout>

          <v-layout v-else>
            <v-btn
              small
              color="primary"
              :disabled="!(isValid && isChanged) || entity.isRemoved || loading || !isValidEntityTitle"
              data-test="saveButton"
              class="mt-3 mr-2"
              @click="submit(false)"
            >
              Сохранить
            </v-btn>
            <v-btn
              small
              data-test="cancelButton"
              class="mt-3"
              @click="cancel"
            >
              Отмена
            </v-btn>
          </v-layout>
        </v-layout>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "../../mixins/confirm-cancel-changes.js";
import { NestedEditorsEventBus } from "../../components/nested-editors/nested-editors-event-bus.js";
import * as countriesApi from "../../backend/countriesApi";
import TitleTextField from "../../components/TitleTextField.vue";

export default {
  components: {
    TitleTextField,
  },
  mixins: [mixin],
  data() {
    return {
      entity: {
        id: 0,
        inputLanguage: "cyrillic",
        title: "",
        prefix: "",
        isoCode: "",
      },
      resource: "countries",
      isValid: false,
      isChanged: false,
      loading: false,
      rules: {
        required: (v) => !!v || "Поле обязательно к заполнению",
        min: (v) =>
          (!!v && v.length >= 3) ||
          "Поле должно содержать не менее 3-х символов",
        prefixLength: (v) =>
          (!!v && (v.length === 2 || v.length === 3)) ||
          "Поле обязательно к заполнению и должно содержать 2 или 3 символа",
        isoCodeLength: (v) => !!v && (v.length === 0 || v.length === 3),
      },
      languageItems: [
        { value: "cyrillic", text: "Кириллица" },
        { value: "latin", text: "Латинница" },
      ],
    };
  },
  computed: {
    isModal() {
      let parent = this.$parent;
      while (parent) {
        if (parent.$options._componentTag === "NestedEditors") {
          return true;
        }
        parent = parent.$parent;
      }
      return false;
    },
    isConfirmed() {
      if (Object.prototype.hasOwnProperty.call(this.entity, "isConfirmed")) {
        return this.entity.isConfirmed;
      }
    },
    isValidEntityTitle() {
      let regex = null;
      if (this.entity.inputLanguage === "cyrillic") {
        regex = /[^A-Z,0-9,.-\s/]/gim;
      }
      if (this.entity.inputLanguage === "latin") {
        regex = /[^А-Я,0-9,.-\s/]/gim;
      }
      return regex.test(this.entity.title);
    },
  },
  mounted() {
    if (this.isModal) {
      NestedEditorsEventBus.$emit("modal-editor-mounted");
    }
  },
  methods: {
    entityChange() {
      this.isChanged = true;
    },
    async submit() {
      this.loading = true;
      const res = await countriesApi.createCountry(
        this.resource,
        this.entity,
      );
      this.showInfo("Страна добавлена");
      this.entity.id = res.data;
      this.loading = false;
      NestedEditorsEventBus.$emit("create", this.entity.id);

      this.loading = false;
    },
    cancel() {
      NestedEditorsEventBus.$emit("close");
    },
    async saveAndClose() {
      await this.submit();
    },
    getMask(controlType) {
      switch (controlType) {
        case "isoCodeAlpha3":
          return "AAA";
        case "prefix":
          return "AAA";
        default:
          return "";
      }
    },
    resetLoaders() {
      this.loading = false;
    },
  },
};
</script>
