export default {
  data() {
    return {
      isChanged: false,
      suppressLeaveConfirm: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (
      !this.suppressLeaveConfirm &&
        this.isChanged &&
        !confirm("Отменить изменения?")
    ) {
      next(false);
    } else {
      next();
    }
  },
};
