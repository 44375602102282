<template>
  <v-app-bar
    color="white"
    flat
    dense
    class="pa-0 ma-0"
    style="width: 500px"
  >
    <v-card
      dense
      class="pa-0 ma-0"
      tile
      flat
    >
      <v-tabs flat>
        <v-tab
          v-for="item in infoItems"
          :key="item.routeName"
          :to="{ name: item.routeName }"
        >
          {{ item.title }}
        </v-tab>
      </v-tabs>
    </v-card>
  </v-app-bar>
</template>

<script>
import infoItems from "@/helpers/infoMenu.js";

export default {
  data: () => ({
    infoItems,
    tab: null,
  }),
};
</script>

<style>
.active {
  opacity: 0.6;
}
</style>
