import Vue from "vue";
import VueRouter from "vue-router";
import auth from "@/auth.js";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      redirect: () => {
        if (auth.loggedIn()) {
          if (auth.isAdmin()) {
            return { name: "adminPanel" };
          } else {
            return { name: "news" };
          }
        } else {
          return { name: "login" };
        }
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/system/SignIn.vue"),
    },
    {
      path: "/news",
      name: "newsList",
      component: () => import("@/views/news/NewsList.vue"),
    },
    {
      path: "/news/:link",
      name: "news",
      component: () => import("@/views/news/News.vue"),
      props: true,
    },
    {
      path: "/terms",
      name: "terms",
      component: () => import("@/views/terms/Terms.vue"),
    },
    {
      path: "/tariffs",
      name: "tariffs",
      component: () => import("@/views/tariffs/Tariffs.vue"),
    },
    {
      path: "/warehouses-outside-ukraine",
      name: "warehouses-outside-ukraine",
      component: () =>
        import("@/views/warehouses-outside-ukraine/WarehousesOutsideUkraine.vue"),
    },
    {
      path: "/contacts",
      name: "contacts",
      component: () => import("@/views/contacts/Contacts.vue"),
    },
    {
      path: "/shippings",
      name: "shippings",
      component: () => import("@/views/shippings/Shippings.vue"),
      props: true,
    },
    {
      path: "/shipping-drafts",
      name: "drafts",
      component: () => import("@/views/drafts/Drafts.vue"),
      props: true,
    },
    {
      path: "/release-orders",
      name: "releaseOrders",
      component: () => import("@/views/release-orders/ReleaseOrders.vue"),
    },
    {
      path: "/finance",
      name: "finance",
      component: () => import("@/views/finance/Finance.vue"),
    },
    {
      path: "/user",
      name: "user",
      component: () => import("@/views/user/User.vue"),
    },
    {
      path: "/first-restore-password",
      name: "changePassword",
      component: () => import("@/views/system/FirstPasswordChange.vue"),
    },
    {
      path: "/restore-password",
      name: "restorePassword",
      component: () => import("@/views/system/RestorePassword.vue"),
    },
    {
      path: "/send-password",
      name: "sendPassword",
      component: () => import("@/views/system/SendPassword.vue"),
    },
    {
      path: "/admin-panel",
      name: "adminPanel",
      component: () => import("@/views/admin-panel/Panel.vue"),
    },
    {
      path: "/users",
      name: "adminUsers",
      component: () => import("@/views/admin-panel/users/Users.vue"),
    },
    {
      path: "/users/:id",
      name: "adminUser",
      component: () => import("@/views/admin-panel/users/User.vue"),
    },
    {
      path: "/shippings/new",
      name: "shipping-new-steps",
      component: () => import("@/views/shippings/new-shipping/NewShipping.vue"),
      props: true,
    },
    {
      path: "/shippings/:id",
      name: "shipping",
      component: () => import("@/views/shippings/Shipping.vue"),
      props: true,
    },
    {
      path: "/goods-details/:id",
      name: "goodsDetail",
      component: () => import("@/views/shippings/goods-details/GoodsDetail.vue"),
      props: true,
    },
    {
      path: "/shippings/:id/messages",
      name: "messages",
      component: () => import("@/views/shippings/messages/Messages.vue"),
      props: true,
    },
    {
      path: "/attachments/:id",
      name: "attachment",
      component: () => import("@/views/shippings/attachments/Attachments.vue"),
      props: true,
    },
    {
      path: "/admin-panel/news",
      name: "newsPosts",
      component: () => import("@/views/admin-panel/posts/news/NewsList.vue"),
    },
    {
      path: "/admin-panel/news/:id",
      name: "news-post",
      component: () => import("@/views/admin-panel/posts/news/NewsPost.vue"),
      props: true,
    },
    {
      path: "/admin-panel/terms",
      name: "termsPosts",
      component: () => import("@/views/admin-panel/posts/terms/TermsList.vue"),
    },
    {
      path: "/admin-panel/terms/:id",
      name: "terms-post",
      component: () => import("@/views/admin-panel/posts/terms/TermsPost.vue"),
      props: true,
    },
    {
      path: "/admin-panel/warehouses",
      name: "warehousesPosts",
      component: () => import("@/views/admin-panel/posts/warehouses/WarehousesList.vue"),
    },
    {
      path: "/admin-panel/warehouses/:id",
      name: "warehouses-post",
      component: () => import("@/views/admin-panel/posts/warehouses/WarehousesPost.vue"),
      props: true,
    },
  ],
});

const originalPush = router.push;
router.push = function push(location, onResolve, onReject)
{
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err;
    }

    return Promise.reject(err);
  });
};

export default router;
