<template>
  <v-layout>
    <v-menu
      v-model="menu"
      bottom
      origin="center center"
      transition="scale-transition"
      offset-y
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-combobox
          v-model="selectedItems"
          :label="label"
          readonly
          hide-details
          outlined
          multiple
          dense
        >
          <template v-slot:append>
            <slot name="append-content">
              <v-layout>
                <v-spacer />
                <v-icon
                  small
                  :data-test="`${dataTest}Menu`"
                  v-on="on"
                  @click="menu=true"
                >
                  mdi-pencil
                </v-icon>
              </v-layout>
            </slot>
          </template>
          <template v-slot:selection="data">
            <v-chip small>
              {{ filter ? $options.filters[filter](data.item) : data.item }}
            </v-chip>
          </template>
        </v-combobox>
      </template>
      <v-card :data-test="`${dataTest}Edit`">
        <v-card-title class="subheading grey--text">
          {{ title }}
        </v-card-title>
        <v-card-text class="py-0">
          <v-layout
            column
            align-content-center
          >
            <v-flex
              v-for="(item, index) in items"
              :key="index"
            >
              <v-text-field
                v-model="item.value"
                :placeholder="label"
                :mask="mask"
                :rules="rules"
                append-icon="mdi-delete"
                hide-details
                outlined
                dense
                class="mb-2"
                @input="isChanged = true"
                @click:append="remove(item.value)"
              />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-text-field
              v-model="newItem"
              :placeholder="label"
              :mask="mask"
              :rules="rules"
              :hint="items.find(i => i.value === newItem) ? hint : ''"
              outlined
              dense
              @input="isChanged = true"
            />
            <v-btn
              icon
              :disabled="!isChanged"
              @click="add"
            >
              <v-icon color="grey">
                mdi-add-box
              </v-icon>
            </v-btn>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            class="primary"
            :disabled="!isChanged"
            :data-test="`${dataTest}Add`"
            @click="apply"
          >
            Ok
          </v-btn>
          <v-btn
            small
            :data-test="`${dataTest}Cancel`"
            @click="cancel"
          >
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: { type: Array, default: () => [] },
    label: { type: String, default: "" },
    mask: { type: String, default: "" },
    hint: { type: String, default: "" },
    filter: { type: String, default: "" },
    rules: { type: Array, default: () => [] },
    title: { type: String, default: "" },
    dataTest: { type: String, default: "" },
  },
  data() {
    return {
      menu: false,
      items: [],
      newItem: "",
      isChanged: false,
    };
  },
  computed: {
    selectedItems() {
      return this.items.map((i) => i.value);
    },
  },
  watch: {
    value: {
      handler() {
        this.initItems(this.value);
      },
      immediate: true,
    },
    menu() {
      this.initItems(this.value);
    },
  },
  methods: {
    initItems(source) {
      if (this.value && this.value.length !== 0) {
        this.items = source.map((v) => {
          return { value: v };
        });
      }
    },
    change() {
      this.menu = false;
      this.isChanged = false;
      this.newItem = "";
      this.$emit("change", this.selectedItems);
    },
    remove(value) {
      this.isChanged = true;
      const index = this.items.indexOf(
        this.items.find((i) => i.value === value),
      );
      this.items.splice(index, 1);
    },
    add() {
      if (this.newItem === "") {
        return;
      }
      if (this.items.length === 0) {
        this.items.push({ value: this.newItem });
      }
      if (!this.items.find((i) => i.value === this.newItem)) {
        this.items.push({ value: this.newItem });
      }
      this.newItem = "";
    },
    apply() {
      this.add();
      this.change();
    },
    cancel() {
      this.newItem = "";
      this.isChanged = false;
      this.menu = false;
    },
  },
};
</script>
