<template>
  <v-layout
    v-if="!pageLoaded"
    :style="{height: innerHeight + 'px'}"
    align-center
    justify-center
    column
  >
    <v-progress-circular
      indeterminate
      :size="50"
      color="primary"
    />
    <h1 class="mt-2 subheading font-weight-bold primary--text">
      {{ title }}
    </h1>
  </v-layout>
</template>

<script>
export default {
  props: ["title", "loading"],
  data() {
    return {
      innerHeight: 0,
    };
  },
  computed: {
    pageLoaded() {
      return !this.loading;
    },
  },
  mounted() {
    this.innerHeight = getInnerHeight();

    function getInnerHeight() {
      const windowHeight = window.innerHeight;
      const footerHeight = document.getElementsByTagName("footer")[0]
        .offsetHeight;

      const container = document.getElementsByClassName("v-main__wrap")[0]
        .children[0];
      const containerStyles = window.getComputedStyle(container, null);
      const containerPadding =
        parseInt(containerStyles.getPropertyValue("padding-top")) +
        parseInt(containerStyles.getPropertyValue("padding-bottom"));

      return windowHeight - footerHeight - containerPadding;
    }
  },
};
</script>
