<template>
  <v-app>
    <v-main
      class="grey lighten-2"
    >
      <v-container
        fluid
        class="pa-0"
      >
        <MainPage />
      </v-container>
    </v-main>

    <v-footer
      app
      class="primary darken-1"
      dark
      height="25"
      style="min-height: 25px; font-size: 12px"
    >
      <span class="px-2">&copy;2021</span>
    </v-footer>

    <v-snackbar
      v-model="errorSnackbar"
      color="error"
      right
    >
      {{ errorMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="infoSnackbar"
      color="primary"
      right
    >
      {{ infoMessage }}
    </v-snackbar>
  </v-app>
</template>

<script>
import MainPage from "@/views/system/MainPage";

export default {
  name: "App",
  components: {
    MainPage,
  },
  data() {
    return {
      errorSnackbar: false,
      errorMessage: "",
      infoSnackbar: false,
      infoMessage: "",
    };
  },
  methods: {
    showError(message) {
      this.errorSnackbar = false;
      this.errorMessage = message;
      this.errorSnackbar = true;
    },
    showInfo(message) {
      this.infoSnackbar = false;
      this.infoMessage = message;
      this.infoSnackbar = true;
    },
  },
};
</script>

<style>
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #d4d4d4;
}

::-webkit-scrollbar-thumb {
  background: #939393;
  border-radius: 8px;
  border: 3px solid #d4d4d4;
}

.table-striped tr,
th {
  background: #ffffff;
}
.table-striped tr:nth-child(even) {
  background: #ffffff;
}
.table-striped tr:nth-child(odd) {
  background: #f5f5f5;
}

table.v-table thead th,
table.v-table tbody td {
  padding: 0 5px !important;
}
table.v-table thead tr:first-child {
  height: 35px !important;
}
table.v-table tbody td,
table.v-table tbody th {
  height: 35px !important;
}
table.v-table {
  line-height: 1.3;
}

</style>
