<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :disabled="disabled"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        slot="activator"
        :style="{paddingTop: padding}"
        :value="dateFormated"
        :label="label"
        :readonly="!isManualEdit"
        hide-details
        :rules="rules"
        :class="incomingClass"
        outlined
        dense
        :prepend-inner-icon="hideDataTypeIcon ? '' : 'mdi-calendar'"
        :clearable="clearable"
        clear-icon="mdi-close"
        :placeholder="isManualEdit?'DD/MM/YYYY':''"
        :mask="isManualEdit?'##.##.####':''"
        :disabled="disabled"
        @click:clear="clear"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      no-title
      locale="ru-ru"
      :readonly="isReadonly"
      :min="min"
      :max="max"
      first-day-of-week="1"
      @input="changeDate"
    />
  </v-menu>
</template>

<script>
export default {
  props: [
    "label",
    "value",
    "readonly",
    "incomingClass",
    "min",
    "max",
    "withoutInitialData",
    "rules",
    "padding",
    "disabled",
    "hideDataTypeIcon",
    "clearable",
    "isManualEdit",
    "scrollValue",
    "withAutocomplete",
  ],
  data() {
    return {
      date: "",
      menu: false,
    };
  },
  computed: {
    isReadonly() {
      return this.readonly ? this.readonly : false;
    },
    dateFormated() {
      if (!this.date) {
        return "";
      }
      return this.moment(this.date).format("DD.MM.YY");
    },
  },
  watch: {
    value() {
      this.setDate(this.value);
    },
    menu() {
      if (this.menu) {
        this.$emit("opened");
      }
      if (this.menu && !this.value && this.withAutocomplete) {
        this.date = this.moment().format("YYYY-MM-DD");
        this.$emit("input", this.date);
      }
    },
    scrollValue() {
      if (!this.scrollValue) {
        return;
      }
      this.menu = false;
    },
  },
  mounted() {
    const input = this.$children[0].$children[0].$refs.input;
    if (!input) {
      return;
    }
    input.addEventListener("input", () => {
      if (input.value) {
        const year = input.value.length == 10 ? input.value.slice(6) : "";
        const mounth = input.value.length > 3 ? input.value.slice(3, 5) : "";
        const day = input.value.length > 1 ? input.value.slice(0, 2) : "";
        if (year && mounth && day) {
          this.date = `${year}-${mounth}-${day}`;
          this.changeDate();
        }
      }
    });
  },
  created() {
    this.setDate();
  },
  methods: {
    setDate() {
      if (this.value) {
        this.date = this.moment(this.value.substr(0, 10)).format("YYYY-MM-DD");
      } else if (
        !this.withoutInitialData &&
        this.withoutInitialData !== undefined
      ) {
        this.date = this.moment().format("YYYY-MM-DD");
        this.$emit("input", this.date);
      } else {
        this.date = "";
      }
    },
    changeDate() {
      this.menu = false;
      this.$emit("input", this.date);
    },
    clear() {
      this.$emit("input", null);
    },
  },
};
</script>
<style scoped>
table .v-menu__activator .v-text-field .v-input__control .v-input__slot:before {
  border-style: none;
}
</style>
<style>
.date-picker .v-input input {
  font-size: 13px !important;
}
</style>
