<template>
  <v-card>
    <v-card-title class="pb-0">
      <h1 class="headline">
        Редактирование Должности
      </h1>
    </v-card-title>
    <v-card-text class="pt-2">
      <v-form v-model="isValid">
        <v-layout column>
          <v-flex xs12>
            <v-layout row>
              <v-flex
                xs6
                class="mt-3"
              >
                <TitleTextField
                  v-model="title"
                  :required="true"
                  :rules="[rules.required, rules.min]"
                  label="Наименование*"
                  class="mx-2 mb-3"
                  @input="isChanged = true"
                />
              </v-flex>
              <v-flex
                xs6
                class="mt-3"
              >
                <MultipleEnumSelect
                  v-model="roles"
                  class="mx-2 mb-3"
                  label="Роли"
                  resource="employee-roles"
                  :return-object="true"
                  @input="isChanged = true"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout class="mt-3">
          <v-btn
            small
            color="primary"
            :disabled="!(isValid && isChanged) || isRemoved || loading"
            class="mr-2"
            @click="submit(false)"
          >
            Сохранить
          </v-btn>
          <v-btn
            small
            @click="cancel"
          >
            Отмена
          </v-btn>
        </v-layout>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "../../mixins/confirm-cancel-changes.js";
import * as positionsApi from "../../backend/positionsApi.js";
import { NestedEditorsEventBus } from "../../components/nested-editors/nested-editors-event-bus.js";
import TitleTextField from "../../components/TitleTextField.vue";
import MultipleEnumSelect from "../../components/MultipleEnumSelect.vue";

export default {
  components: {
    TitleTextField,
    MultipleEnumSelect,
  },
  mixins: [mixin],
  data() {
    return {
      isValid: false,
      isChanged: false,
      rules: {
        empty: (v) => !!v || "",
        required: (v) => !!v || "Поле обязательно к заполнению",
        min: (v) =>
          v.length >= 3 || "Поле должно содержать не менее 3-х символов",
      },
      resource: "positions",
      addMessage: "Должность добавлена",
      editMessage: "Должность изменена",
      title: "",
      roles: [],
      isRemoved: false,
      loading: false,
    };
  },
  computed: {
    isModal() {
      let parent = this.$parent;
      while (parent) {
        if (parent.$options._componentTag === "NestedEditors") {
          return true;
        }
        parent = parent.$parent;
      }
      return false;
    },
  },
  mounted() {
    NestedEditorsEventBus.$emit("modal-editor-mounted");
  },
  methods: {
    async submit() {
      const roleIds = [];
      if (this.roles != null) {
        this.roles.map((r) => roleIds.push(r.id));
      }
      const position = {
        title: this.title,
        roleIds,
      };
      this.loading = true;
      const res = await positionsApi.createPosition(this.resource, position);
      this.showInfo(this.addMessage);
      this.isChanged = false;
      this.loading = false;
      NestedEditorsEventBus.$emit("create", res.data);
    },
    cancel() {
      NestedEditorsEventBus.$emit("close");
    },
    async saveAndClose() {
      await this.submit();
    },
    resetLoaders() {
      this.loading = false;
    },
  },
};
</script>
