export default [
  {
    title: "Пользователи",
    name: "users",
    routeName: "adminUsers",
    active: true,
  },
  {
    title: "Новости",
    name: "newsPosts",
    routeName: "newsPosts",
    active: true,
  },
  {
    title: "Наши условия",
    name: "termsPosts",
    routeName: "termsPosts",
    active: true,
  },
  {
    title: "Склады вне Украины",
    name: "warehousesPosts",
    routeName: "warehousesPosts",
    active: true,
  },
];