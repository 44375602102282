import http from "./http.js";

async function fetchAllCities(resource, queryParams) {
  const response = await http.get(`isg-logistics/${resource}/references?${queryParams}`);
  return response.data.map(i => {
    return {
      text: i.title,
      value: i.id,
      country: i.country ? {
        id: i.country.id,
        title: i.country.title,
        inputLanguage: i.country.inputLanguage,
      } : null,
      region: i.region ? {
        id: i.region.id,
        title: i.region.title,
      } : null,
      district: i.district ? {
        id: i.district.id,
        title: i.district.title,
      } : null,
    };
  });
}

async function getCityReference(resource, id) {
  const response = await http.get(`isg-logistics/${resource}/references/${id}`);
  return {
    id: response.data.id,
    title: response.data.title,
    country: {
      id: response.data.country.id,
      title: response.data.country.title,
      inputLanguage: response.data.country.inputLanguage,
    },
    region: {
      id: response.data.region.id,
      title: response.data.region.title,
    },
    district: {
      id: response.data.district.id,
      title: response.data.district.title,
    },
    isRemoved: response.data.isRemoved,
  };
}

async function getSearchReference(resource, search) {
  const response = await http.get(
    `isg-logistics/${resource}/references?searchTerm=${search}`,
  );
  return response.data.map(i => {
    return {
      text: i.title,
      value: i.id,
      country: {
        id: i.country.id,
        title: i.country.title,
        inputLanguage: i.country.inputLanguage,
      },
    };
  });
}

async function createCity(resource, entity) {
  const response = await http.post(`isg-logistics/${resource}`, entity);
  return response;
}

export {
  fetchAllCities,
  getSearchReference,
  getCityReference,
  createCity,
};