<template>
  <div>
    <h2 class="mb-2">
      Места погрузки
    </h2>
    <v-btn
      v-if="!editor"
      slot="activator"
      small
      data-test="loadingPlaceEditor"
      @click="showItemEditor(null)"
    >
      Добавить
    </v-btn>
    <LoadingPlaceEditor
      v-if="editor"
      :value="editedItem"
      @create="create"
      @change="change"
      @cancel="closeItemEditor"
    />
    <v-data-table
      v-else
      :headers="headers"
      :items="items"
      hide-default-footer
      no-data-text="Нет данных"
      class="elevation-1"
    >
      <template v-slot:item="{ item }">
        <td>
          {{ item.address ? item.address.title : "" }}
        </td>
        <td>
          {{ item.timetable ? item.timetable : "" }}
        </td>
        <td>
          {{ contactPersonsNames(item) }}
        </td>
        <td class="text-sm-right">
          <v-layout row>
            <v-btn
              icon
              class="mx-0 ma-0"
              data-test="loadingPlaceEdit"
              @click="showItemEditor(item)"
            >
              <v-icon color="grey darken-1">
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-btn
              icon
              class="mx-0 ma-0"
              data-test="loadingPlaceDelete"
              @click="remove(item)"
            >
              <v-icon color="grey darken-1">
                mdi-delete
              </v-icon>
            </v-btn>
          </v-layout>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Editor from "./LoadingPlaceEditor.vue";

export default {
  components: {
    LoadingPlaceEditor: Editor,
  },
  props: ["factoryId", "items"],
  data() {
    return {
      editor: false,
      editedItem: null,
      newLoadingPlace: [],
      loadingPlaces: this.items,
      columns: [
        { title: "Адрес", name: "address" },
        { title: "График работы", name: "timetable" },
        { title: "Контактное лицо", name: "contactPersons" },
      ],
    };
  },
  computed: {
    headers() {
      const headers = this.columns.map((c) => {
        return {
          text: c.title,
          sortable: false,
        };
      });
      headers.push({ text: "", sortable: false });
      return headers;
    },
  },
  watch: {
    items(val) {
      this.loadingPlaces = val;
    },
  },
  created() {
    this.loadingPlaces = this.items;
  },
  methods: {
    contactPersonsNames(item) {
      if (!item || !item.contactPersons) {
        return "";
      }
      return item.contactPersons.map((cp) => cp.title).join("; ");
    },
    create(item) {
      if (item.id === 0) {
        this.loadingPlaces.push({
          address: item.address,
          timetable: item.timetable,
          contactPersons: item.contactPersons,
        });
      }
      this.closeItemEditor();
      this.$emit("create", item);
    },
    change(item) {
      this.editedItem.address = item.address;
      this.editedItem.timetable = item.timetable;
      this.editedItem.contactPersons = [...item.contactPersons];
      this.closeItemEditor();
      this.$emit("change");
    },
    remove(item) {
      const index = this.items.indexOf(item);
      if (index > -1) {
        this.items.splice(index, 1);
        this.$emit("delete");
      }
    },
    close() {
      this.items.splice(this.items.length - 1, 1);
      this.$emit("cancel");
    },
    showItemEditor(item) {
      this.editedItem = item;
      this.editor = true;
    },
    closeItemEditor() {
      setTimeout(() => {
        this.editor = false;
        this.editedItem = null;
      }, 200);
    },
  },
};
</script>
