import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import auth from "./auth";
import axios from "axios";

import "vuetify/dist/vuetify.min.css";

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import CKEditor from "ckeditor4-vue";
Vue.use(CKEditor);

import Reference from "./components/Reference.vue";
import TinyReference from "./components/TinyReference.vue";
import DatePicker from "./components/DatePicker.vue";
import DateRangePicker from "./components/DateRangePicker.vue";
import DecimalEditor from "./components/DecimalEditor.vue";
import EnumSelect from "./components/EnumSelect.vue";
import Loader from "./components/Loader.vue";
import MultipleReferenceCompact from "./components/MultipleReferenceCompact.vue";
Vue.component("DecimalEditor", DecimalEditor);
Vue.component("app-reference", Reference);
Vue.component("app-tiny-reference", TinyReference);
Vue.component("app-date-picker", DatePicker);
Vue.component("DateRangePicker", DateRangePicker);
Vue.component("EnumSelect", EnumSelect);
Vue.component("app-loader", Loader);
Vue.component("MultipleReferenceCompact", MultipleReferenceCompact);

import Address from "./views/edit-forms/Address.vue";
import City from "./views/edit-forms/City.vue";
import ContactPerson from "./views/edit-forms/ContactPerson.vue";
import Country from "./views/edit-forms/Country.vue";
import Currency from "./views/edit-forms/Currency.vue";
import District from "./views/edit-forms/District.vue";
import Factory from "./views/edit-forms/Factory.vue";
import IndexSelect from "./views/edit-forms/IndexSelect.vue";
import LoadingPlaceEditor from "./views/edit-forms/LoadingPlaceEditor.vue";
import LoadingPlaces from "./views/edit-forms/LoadingPlaces.vue";
import Position from "./views/edit-forms/Position.vue";
import Region from "./views/edit-forms/Region.vue";
import LoadingPlaceModal from "./views/shippings/new-shipping/LoadingPlaceModal.vue";
Vue.component("Address", Address);
Vue.component("City", City);
Vue.component("ContactPerson", ContactPerson);
Vue.component("Country", Country);
Vue.component("Currency", Currency);
Vue.component("District", District);
Vue.component("Factory", Factory);
Vue.component("IndexSelect", IndexSelect);
Vue.component("LoadingPlaceEditor", LoadingPlaceEditor);
Vue.component("LoadingPlaces", LoadingPlaces);
Vue.component("Position", Position);
Vue.component("Region", Region);
Vue.component("LoadingPlaceModal", LoadingPlaceModal);


Vue.config.errorHandler = (err, vm) => {
  if (err.message) {
    console.log(err);
    const message = err.status == 500 ? err.message : "Ошибка приложения";
    vm.$root.showError(message);
    callInAllComponents({ method: "resetLoaders", component: vm.$root });
  } else {
    return Promise.reject(err);
  }

  function callInAllComponents(value) {
    if (value.component[value.method]) {
      value.component[value.method]();
    }
    if (value.component?.$children) {
      value.component.$children.forEach(c => callInAllComponents({ method: "resetLoaders", component: c }));
    }
  }
};

Vue.config.productionTip = false;

import moment from "moment";
moment.locale("uk");
Vue.mixin({
  data() {
    return {
      get moment() {
        return moment;
      },
      get lodash() {
        return require("lodash");
      },
    };
  },
});

Vue.mixin({
  methods: {
    showError(message) {
      const appComponent = this.$root.$children[0];
      appComponent.showError(message);
    },
    showInfo(message) {
      const appComponent = this.$root.$children[0];
      appComponent.showInfo(message);
    },
  },
});

router.beforeEach((to, from, next) => {
  if (to.name === "sendPassword") {
    next();
  } else if ((to.name === "changePassword" || to.name === "restorePassword") && from.name === "login") {
    next({ name: "login" });
  } else if (to.name === "changePassword" || to.name === "restorePassword") {
    next ();
  } else if (to.name !== "login" && !auth.loggedIn()) {
    auth.logout();
    next({ name: "login" });
  } else {
    next();
  }
});

const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

http.interceptors.request.use(
  function(config) {
    config.headers["Authorization"] = "Bearer " + auth.getToken();
    return config;
  },
  function(error) {
    return Promise.reject(error);
  },
);

Vue.prototype.$http = http;

const url = process.env.VUE_APP_API_URL;
if (url.includes("dev")) {
  Vue.prototype.$url = process.env.VUE_APP_DEV;
} else if (url.includes("test")) {
  Vue.prototype.$url = process.env.VUE_APP_TEST;
} else {
  Vue.prototype.$url = process.env.VUE_APP_PROD;
}


Vue.filter("phoneInternational", function(value) {
  return value.replace(
    /(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})/,
    "+$1($2)$3-$4-$5",
  );
});

Vue.filter("trimSpaces", value => {
  if (!value) {
    return;
  }
  return value.replace(/\s+/g, " ").trim();
});

Vue.filter("date", function(value) {
  const moment = require("moment");
  const date = moment(value);
  if (date.isValid()) {
    return date.format("DD.MM.YY");
  }
  return "";
});

Vue.filter("dateTime", function(value) {
  const moment = require("moment");
  const date = moment(value);
  if (date.isValid()) {
    return date.format("DD.MM.YY HH:mm");
  }
  return "";
});


Vue.filter("separateDigits", function(value) {
  return value.toString().replace(/(\d)(?=(\d{3})+(\D|$))/g, "$1 ");
});

Vue.filter("dateRange", function(value) {
  const date = {
    from: value.From || value.from || value.dateFrom,
    to: value.To || value.to || value.dateTo,
  };
  const moment = require("moment");
  if (date && date.from !== date.to) {
    return `${moment(date.from).format("DD.MM.YY")} - ${moment(date.to).format(
      "DD.MM.YY",
    )}`;
  } else if (date && date.from === date.to) {
    return moment(date.from).format("DD.MM.YY");
  } else {
    return "";
  }
});

Vue.filter("stringList", (value, dictinct = false, dataTitle = "title") => {
  if (!Array.isArray(value)) {
    return "";
  }
  let stringList = value.map(i => {
    if (i && typeof i === "object") {
      return Object.prototype.hasOwnProperty.call(i, dataTitle) ? i[dataTitle] : "";
    } else if (i && typeof i === "string") {
      return i;
    } else {
      return "";
    }
  });
  if (dictinct) {
    stringList = stringList.filter(
      (str, index, arr) => arr.indexOf(str) == index,
    );
  }
  return stringList.join("; ");
});

Vue.filter("phoneInternal", value => {
  if (value.length > 12) {
    return `+${value.slice(0, 12)} *${value.slice(12)}`;
  } else {
    return `+${value}`;
  }
});

Vue.filter("cropText", function(value, limit) {
  if (value.length > limit) {
    return value.substring(0, limit) + "...";
  }
  return value;
});

const vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount("#app");

export { vue };
