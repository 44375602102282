import http from "./http.js";

async function selectIndex(filter) {
  const response = await http.get(`isg-logistics/locations?${filter}`);
  return response.data.map(i => {
    return {
      id: i.id,
      code: i.code,
      country: {
        id: i.country.id,
        isRemoved: i.country.isRemoved,
        title: i.country.title,
      },
      district: i.district ? {
        id: i.district.Id,
        isRemoved: i.district.isRemoved,
        title: i.district.title,
      } : null,
      region: {
        id: i.region.id,
        isRemoved: i.region.isRemoved,
        title: i.region.title,
      },
      city: {
        id: i.city.id,
        isRemoved: i.city.isRemoved,
        title: i.city.title,
      },
      fullTitle: `${i.code}*${i.country.title}*${i.region.title}*${i.city.title}`,
    };
  });
}

async function createAddress(resource, entity) {
  const response = await http.post(`/${resource}`, entity);
  return response;
}


export {
  selectIndex,
  createAddress,
};