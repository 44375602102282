<template>
  <v-card :flat="isModal ? true : false">
    <v-card-title class="pb-0">
      <h1 class="headline">
        Редактирование Адреса
      </h1>
    </v-card-title>
    <v-card-text class="pt-2">
      <v-form v-model="isValid">
        <v-layout column>
          <v-layout
            row
            class="mb-3"
          >
            <v-flex>
              <IndexSelect
                v-if="isNew"
                v-model="index"
                :country-id="entity.country ? entity.country.id : 0"
                :city-id="entity.city ? entity.city.id : 0"
                :required="true"
                class="mx-2 mt-3"
                @input="indexChange"
              />
              <TitleTextField
                v-else
                v-model="entity.postcode"
                :required="true"
                :readonly="true"
                label="Индекс*"
                class="mx-2  mt-3"
              />
            </v-flex>
          </v-layout>

          <v-layout
            row
            class="mb-3"
          >
            <v-flex xs6>
              <CountryReference
                v-model="entity.country"
                :rules="[v => !!v || '']"
                clearable
                label="Страна*"
                class="mx-2"
              />
            </v-flex>
            <v-flex xs6>
              <RegionReference
                v-model="entity.region"
                :rules="[v => !!v || '']"
                :readonly="true"
                :clearable="false"
                :country-id="entity.country ? entity.country.id : 0"
                label="Регион*"
                class="mx-2"
              />
            </v-flex>
          </v-layout>

          <v-layout
            row
            class="mb-3"
          >
            <v-flex xs6>
              <DistrictReference
                v-model="entity.district"
                :readonly="true"
                :clearable="false"
                :country-id="entity.country ? entity.country.id : 0"
                :region-id="entity.region ? entity.region.id : 0"
                label="Область"
                class="mx-2"
              />
            </v-flex>
            <v-flex xs6>
              <CityReference
                v-model="entity.city"
                :rules="[v => !!v || '']"
                clearable
                :country-id="entity.country ? entity.country.id : 0"
                :region-id="entity.region ? entity.region.id : 0"
                :district-id="entity.district ? entity.district.id : 0"
                label="Город*"
                class="mx-2"
              />
            </v-flex>
          </v-layout>
          <v-layout
            row
            class="mb-2"
          >
            <v-flex
              xs6
            >
              <TitleTextField
                v-model="entity.street"
                :required="true"
                :input-language="inputLanguage"
                :rules="[
                  v => !!v || 'Улица обязательна к заполнению',
                  v =>
                    v.length >= 3 ||
                    'Улица должна содержать не менее 3-х символов'
                ]"
                label="Улица*"
                class="mx-2"
                @input="entityChange"
              />
            </v-flex>
            <v-flex
              xs6
            >
              <TitleTextField
                v-model="entity.buildNumber"
                :required="true"
                :input-language="inputLanguage"
                :rules="[v => !!v || 'Дом обязателен к заполнению']"
                label="Дом*"
                class="mx-2"
                @input="entityChange"
              />
            </v-flex>
          </v-layout>
        </v-layout>

        <v-layout>
          <v-btn
            small
            color="primary"
            :disabled="!(isValid && isChanged) || entity.isRemoved || loading"
            data-test="saveButton"
            class="mr-2"
            @click="submit(false)"
          >
            Сохранить
          </v-btn>
          <v-btn
            small
            data-test="cancelButton"
            @click="cancel"
          >
            Отмена
          </v-btn>
        </v-layout>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "./../../mixins/confirm-cancel-changes.js";
import CountryReference from "../../components/references/CountryReference.vue";
import CityReference from "../../components/references/CityReference.vue";
import { NestedEditorsEventBus } from "../../components/nested-editors/nested-editors-event-bus.js";
import IndexSelect from "./IndexSelect.vue";
import RegionReference from "../../components/references/RegionReference.vue";
import DistrictReference from "../../components/references/DistrictReference.vue";
import * as addressesApi from "../../backend/addressesApi.js";
import TitleTextField from "../../components/TitleTextField.vue";

export default {
  components: {
    CountryReference,
    CityReference,
    IndexSelect,
    RegionReference,
    DistrictReference,
    TitleTextField,
  },
  mixins: [mixin],
  data() {
    return {
      entity: {
        id: 0,
        country: null,
        district: null,
        region: null,
        city: null,
        street: "",
        buildNumber: "",
        postcode: "",
      },
      entityRestored: false,
      resource: "addresses",
      isValid: false,
      isChanged: false,
      loading: false,
      index: null,
    };
  },
  computed: {
    inputLanguage() {
      return this.entity.country ? this.entity.country.inputLanguage : "";
    },
    isModal() {
      let parent = this.$parent;
      while (parent) {
        if (parent.$options._componentTag === "NestedEditors") {
          return true;
        }
        parent = parent.$parent;
      }
      return false;
    },
    isConfirmed() {
      if (Object.prototype.hasOwnProperty.call(this.entity, "isConfirmed")) {
        return this.entity.isConfirmed;
      }
    },
    isNew() {
      return +this.id ? false : true;
    },
  },
  mounted() {
    if (this.isModal) {
      NestedEditorsEventBus.$emit("modal-editor-mounted");
    }
  },
  methods: {
    entityChange() {
      if (!this.entityRestored && !this.isModal) {
        return;
      }
      this.isChanged = true;
    },
    indexChange(payload) {
      this.entity.country = null;
      this.cleanEntity();
      if (payload) {
        this.index = {
          id: payload.id,
          code: payload.code,
          district: payload.district,
          region: payload.region,
          city: payload.city,
          country: payload.country,
        };
        this.entity.postcode = this.index.code;
        this.entity.district = payload.district ? payload.district : null;
        this.entity.region = payload.region ? payload.region : null;
        this.entity.city = payload.city ? payload.city : null;
        this.entity.country = payload.country ? payload.country : null;
      }
      this.entityChange();
    },
    async submit() {
      const item = {
        id: this.entity.id,
        countryId: this.entity.country.id,
        cityId: this.entity.city.id,
        street: this.entity.street,
        buildNumber: this.entity.buildNumber,
        postcode: this.entity.postcode,
      };
      this.loading = true;
      const res = await addressesApi.createAddress(this.resource, item);
      this.showInfo("Адрес добавлен");
      this.entity.id = res.data;
      NestedEditorsEventBus.$emit("create", this.entity.id);
      this.loading = false;
    },
    cancel() {
      NestedEditorsEventBus.$emit("close");
    },
    async saveAndClose() {
      await this.submit();
    },
    cleanEntity() {
      this.entity.district = null;
      this.entity.region = null;
      this.entity.city = null;
      this.entity.postcode = "";
      this.index = null;
    },
    resetLoaders() {
      this.loading = false;
    },
  },
};
</script>
