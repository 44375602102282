const upload = function() {
  return new Promise(resolve => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = "multiple";
    document.body.appendChild(input);
    input.click();

    input.onchange = ev => {
      const result = [];
      const files = ev.target.files;
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = e => {
          const arrayBuffer = e.target.result;
          const array = Array.from(new Uint8Array(arrayBuffer));
          result.push({
            originName: files[i].name,
            sourceName: files[i].name,
            data: array,
          });
          if (result.length === files.length) {
            input.remove();
            resolve(result);
          }
        };
        reader.readAsArrayBuffer(files[i]);
      }
    };
  });
};

export default upload;
