export default [
  {
    title: "Новости",
    name: "news",
    routeName: "newsList",
    active: true,
    width: 90,
  },
  {
    title: "Наши условия",
    name: "terms",
    routeName: "terms",
    active: true,
    width: 150,
  },
  {
    title: "Склады вне Украины",
    name: "warehousesOutsideUkraine",
    routeName: "warehouses-outside-ukraine",
    active: true,
    width: 190,
  },
];