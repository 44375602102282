import storeList from "../../../store/store-list.js";

const list = new storeList();

const state = {
  ...list.state,
};
const getters = {
  ...list.getters,
};
const actions = {
  ...list.actions,
};
const mutations = {
  ...list.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
