<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    offset-y
    :disabled="disabled"
    transition="scale-transition"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        :value="formatedDates"
        :label="label"
        :rules="rules"
        outlined
        :error="error"
        :style="{paddingTop: padding}"
        readonly
        hide-details
        :clearable="clearable"
        :prepend-inner-icon="hideDataTypeIcon ? '' : 'mdi-calendar'"
        dense
        @click:clear="remove"
        v-on="on"
      />
    </template>

    <v-card class="v-date-range__pickers">
      <v-date-picker
        v-model="dates"
        multiple
        no-title
        locale="ru-ru"
        :readonly="isReadonly"
        :min="min"
        :max="max"
        class="mr-1 elevation-0"
        :events="highlightDates"
        :event-color="highlightClasses"
        first-day-of-week="1"
        reactive
        @input="change"
      />
      <v-card-actions>
        <v-spacer />
        <v-btn
          small
          color="primary"
          :disabled="!firstDate"
          @click="apply"
        >
          OK
        </v-btn>
        <v-btn
          text
          small
          @click="cancel"
        >
          Отмена
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  props: [
    "label",
    "value",
    "readonly",
    "min",
    "max",
    "rules",
    "padding",
    "disabled",
    "error",
    "clearable",
    "hideDataTypeIcon",
    "scrollValue",
  ],
  data() {
    return {
      menu: false,
      highlightColor: "blue lighten-5",
      highlightDates: [],
      highlightClasses: {},
      dates: [],
      firstDate: null,
      secondDate: null,
      isChanged: false,
    };
  },
  computed: {
    isReadonly() {
      return this.readonly ? this.readonly : false;
    },
    formatedDates() {
      if (this.value && this.value.dateFrom && this.value.dateTo) {
        return this.$options.filters.dateRange({
          from: this.value.dateFrom,
          to: this.value.dateTo,
        });
      } else if (this.value && this.value.dateFrom && !this.value.dateTo) {
        return this.$options.filters.date(this.value.dateFrom);
      } else {
        return "";
      }
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.initDates(val);
        }
      },
      immediate: true,
    },
    menu() {
      if (this.menu) {
        this.$emit("open");
      }
    },
    firstDate() {
      this.highlight();
    },
    secondDate() {
      this.highlight();
    },
    scrollValue() {
      if (!this.scrollValue) {
        return;
      }
      this.menu = false;
    },
  },
  methods: {
    change() {
      if (
        this.isChanged ||
        (this.firstDate === null && this.secondDate === null)
      ) {
        if (this.isChanged) {
          if (this.dates.length === 3) {
            this.firstDate = this.dates[2];
          } else {
            this.firstDate =
              !this.dates[0] || this.firstDate != this.dates[0]
                ? this.firstDate
                : this.secondDate
                  ? this.secondDate
                  : this.dates[1];
          }
          this.isChanged = false;
        } else {
          this.firstDate = this.dates[0];
        }
        this.secondDate = null;
      } else if (this.firstDate !== null && this.secondDate === null) {
        if (this.firstDate && !this.dates[0]) {
          this.apply();
        } else {
          this.secondDate = this.dates[1];
        }
      } else if (this.firstDate !== null && this.secondDate !== null) {
        this.firstDate = this.dates[2]
          ? this.dates[2]
          : this.firstDate != this.dates[0]
            ? this.firstDate
            : this.secondDate;
        this.secondDate = null;
      }

      if (
        this.firstDate &&
        this.secondDate &&
        this.moment(this.secondDate).isBefore(this.firstDate)
      ) {
        const tmp = this.firstDate;
        this.firstDate = this.secondDate;
        this.secondDate = tmp;
      }
      this.fillDates();
    },
    initDates() {
      if (!this.value) {
        this.reset();
      }

      this.firstDate = this.value.dateFrom
        ? this.moment(this.value.dateFrom.substr(0, 10)).format("YYYY-MM-DD")
        : null;
      this.secondDate = this.value.dateTo
        ? this.moment(this.value.dateTo.substr(0, 10)).format("YYYY-MM-DD")
        : null;

      this.highlight();
      this.fillDates();
      this.isChanged = true;
    },
    fillDates() {
      this.dates = [];
      if (this.firstDate) {
        this.dates.push(this.firstDate);
      }
      if (this.secondDate) {
        this.dates.push(this.secondDate);
      }
    },
    apply() {
      this.isChanged = true;
      this.$emit("input", {
        dateFrom: this.firstDate,
        dateTo: this.secondDate,
      });
      this.menu = false;
    },
    highlight() {
      if (this.firstDate && this.secondDate) {
        const dates = []; //массив дат диапазона
        const classes = {};
        const start = this.moment(this.firstDate).unix();
        const end = this.moment(this.secondDate).unix();
        const diff = Math.abs(start - end) / 86400; //берем разницу дней между стартовой и конечной датой

        for (let i = 0; i <= diff; i++) {
          const date = this.moment((start + 86400 * i) * 1000).format(
            "YYYY-MM-DD",
          ); //начиная со стартовой даты диапазона добавляем день, если i=0 получается начальная дата
          dates.push(date); //добавляем дату в массив
          const classesArr = [];
          classesArr.push("v-date-range__in-range");
          classesArr.push(this.highlightColor);

          if (i === 0) {
            classesArr.push("v-date-range__range-start");
          }

          if (i === diff) {
            classesArr.push("v-date-range__range-end");
          }

          classes[date] = classesArr.join(" ");
        }
        this.highlightDates = dates;
        this.highlightClasses = classes;
      } else {
        this.highlightDates = [];
        this.highlightClasses = {};
      }
    },
    cancel() {
      if (this.value) {
        this.initDates();
      } else {
        this.reset();
      }
      this.menu = false;
    },
    remove() {
      this.reset();
      this.isChanged = false;
      this.$emit("input", null);
    },
    reset() {
      this.dates = [];
      this.firstDate = null;
      this.secondDate = null;
      this.highlightDates = [];
      this.highlightClasses = {};
    },
  },
};
</script>

<style scoped>
.v-date-range__pickers >>> .v-date-picker-table__events {
  height: 100% !important;
  width: 38px !important;
  top: 0 !important;
  z-index: -1 !important;
}

.v-date-range__pickers >>> .v-date-range__in-range {
  height: 100% !important;
  width: 100% !important;
  margin: 0 !important;
  border-radius: 0 !important;
}

.v-date-range__pickers >>> .v-date-range__in-range.v-date-range__range-start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.v-date-range__pickers >>> .v-date-range__in-range.v-date-range__range-end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
</style>

<style>
table .v-menu__activator .v-text-field .v-input__control .v-input__slot:before {
  border-style: none;
}
</style>
