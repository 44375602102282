<template>
  <v-autocomplete
    v-model="selected"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    :disabled="disabled"
    :required="required"
    item-text="fullTitle"
    item-value="id"
    hide-selected
    no-data-text="Данные отсутствуют"
    no-filter
    clearable
    placeholder="Поиск по индексу"
    return-object
    outlined
    hide-details
    dense
    label="Индекс*"
    :rules="[v => !!v || '']"
    @change="handleItemChange"
  />
</template>
<script>
import { selectIndex } from "../../backend/addressesApi.js";

export default {
  props: ["value", "countryId", "disabled", "required", "cityId"],
  data() {
    return {
      selected: null,
      search: null,
      items: [],
      loading: false,
    };
  },
  computed: {
    filter() {
      let filter = "";
      if (this.search) {
        filter += `code=${this.search}&`;
      }
      if (this.countryId) {
        filter += `countryIds=${this.countryId}&`;
      }
      if (this.cityId) {
        filter += `cityIds=${this.cityId}`;
      }
      return filter;
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.value) {
          this.selected = null;
          this.search = null;
          this.items = [];
        }
        if (this.selected && this.value.code === this.selected.code) {
          return;
        }
        const selectedItem = this.items.find((i) => i.id === this.value.id);
        if (selectedItem) {
          this.selected = selectedItem;
        } else {
          this.setSelectedElement(this.value);
        }
      },
      deep: true,
    },
    search() {
      if (!this.search) {
        this.selected = null;
        this.items = [];
        return;
      }

      if (this.selected && this.value.id === this.selected.id) {
        return;
      }

      if (this.search && this.search.length >= 3) {
        setTimeout(() => {
          this.fetchAll();
        }, 1000);
      }
    },
    countryId() {
      if (this.selected && this.value.id === this.selected.id) {
        return;
      }

      if (this.countryId) {
        setTimeout(() => {
          this.fetchAll();
        }, 1000);
      }
    },
    cityId() {
      if (this.selected && this.value.id === this.selected.id) {
        return;
      }

      if (this.cityId) {
        setTimeout(() => {
          this.fetchAll();
        }, 1000);
      }
    },
  },
  methods: {
    async fetchAll() {
      this.loading = true;
      this.items = await selectIndex(this.filter);
      this.loading = false;
    },
    setSelectedElement(value) {
      if (value) {
        const newItem = {
          id: value.id,
          code: value.code,
          district: value.district,
          country: value.country,
          region: value.region,
          city: value.city,
        };
        this.selected = newItem;
      }
    },
    handleItemChange() {
      this.$emit(
        "input",
        this.selected
          ? {
            id: this.selected.id,
            code: this.selected.code,
            country: this.selected.country
              ? {
                id: this.selected.country.id,
                isRemoved: this.selected.country.isRemoved,
                title: this.selected.country.title,
              }
              : null,
            district: this.selected.district
              ? {
                id: this.selected.district.id,
                isRemoved: this.selected.district.isRemoved,
                title: this.selected.district.title,
              }
              : null,
            region: this.selected.region
              ? {
                id: this.selected.region.id,
                isRemoved: this.selected.region.isRemoved,
                title: this.selected.region.title,
              }
              : null,
            city: this.selected.city
              ? {
                id: this.selected.city.id,
                isRemoved: this.selected.city.isRemoved,
                title: this.selected.city.title,
              }
              : null,
          }
          : null,
      );
    },
  },
};
</script>
