import http from "./http.js";

async function getRegionReference(resource, id) {
  const response = await http.get(`isg-logistics/${resource}/references/${id}`);
  return {
    id: response.data.id,
    title: response.data.title,
    code: response.data.code,
    country: {
      id: response.data.country.id,
      title: response.data.country.title,
      inputLanguage: response.data.country.inputLanguage,
    },
    isRemoved: response.data.isRemoved,
  };
}

async function fetchAllRegions(resource, queryParams) {
  const response = await http.get(`isg-logistics/${resource}/references?${queryParams}`);
  return response.data.map(i => {
    return {
      text: i.title,
      value: i.id,
      code: i.code,
      country: {
        id: i.country.id,
        title: i.country.title,
        inputLanguage: i.country.inputLanguage,
      },
    };
  });
}

async function searchReferencies(resource, search) {
  const response = await http.get(
    `isg-logistics/${resource}/references?searchTerm=${search}`,
  );
  if (Array.isArray(response.data)) {
    return response.data.map(i => {
      return {
        text: i.title,
        value: i.id,
        code: i.code,
        country: {
          id: i.country.id,
          title: i.country.title,
          inputLanguage: i.country.inputLanguage,
        },
      };
    });
  } else {
    response.data = [];
    return response;
  }
}

async function createRegion(resource, entity) {
  const response = await http.post(`isg-logistics/${resource}`, entity);
  return response;
}
export {
  fetchAllRegions,
  getRegionReference,
  searchReferencies,
  createRegion,
};