<template>
  <v-card :flat="true">
    <v-card-title class="pb-0">
      <h1 class="headline mb-2">
        Редактирование места погрузки
      </h1>
    </v-card-title>
    <v-card-text class="pt-2">
      <v-form v-model="isValid">
        <v-layout column>
          <app-reference
            v-model="entity.address"
            component-name="Address"
            :rules="[rules.empty]"
            label="Адрес"
            resource="addresses"
            @input="isChanged = true"
          />
          <v-text-field
            v-model="entity.timetable"
            :rules="[rules.required, rules.min]"
            :required="true"
            outlined
            dense
            hide-details
            label="График работы"
            class="my-3"
            @input="isChanged = true"
          />
          <MultipleReferenceCompact
            v-model="entity.contactPersons"
            :rules="[rules.empty]"
            label="Контактное лицо"
            resource="contact-persons"
            component-name="ContactPerson"
            @input="isChanged = true"
          />
        </v-layout>

        <v-btn
          small
          color="primary"
          :disabled="!(isValid && isChanged)"
          class="mt-3 mr-2"
          @click="submit"
        >
          Ок
        </v-btn>
        <v-btn
          small
          class="mt-3"
          @click="cancel"
        >
          Отмена
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { NestedEditorsEventBus } from "../../../components/nested-editors/nested-editors-event-bus.js";
import { createFactoryLoadingPlace } from "../../../backend/newShippingApi.js";

export default {
  props: ["factoryId"],
  data() {
    return {
      isValid: false,
      isChanged: false,
      rules: {
        empty: v => !!v || "",
        required: v => !!v || "Поле обязательно к заполнению",
        min: v => v.length >= 3 || "Поле должно содержать не менее 3-х символов",
      },
      entity: {
        address: null,
        timetable: "",
        contactPersons: null,
      },
    };
  },
  mounted() {
    NestedEditorsEventBus.$emit("modal-editor-mounted");
  },
  methods: {
    async submit() {
      const contactPersonsIds = [];
      if (this.entity.contactPersons) {
        this.entity.contactPersons.forEach(cp => contactPersonsIds.push(cp.id));
      }

      const res = await createFactoryLoadingPlace(
        this.factoryId,
        this.entity.address.id,
        this.entity.timetable,
        this.entity.contactPersons ? contactPersonsIds : null,
      );

      this.showInfo("Место погрузки добавлено");
      this.isChanged = false;
      NestedEditorsEventBus.$emit("create", res);
    },
    cancel() {
      NestedEditorsEventBus.$emit("close");
    },
  },
};
</script>
