import decode from "@/helpers/decode.js";
import Storage from "@/services/storage.js";

const storage = new Storage(window.localStorage);

const roles = {
  ADMINISTRATOR: 0,
  USER: 1,
};

const rolesDict = [
  { value: roles.ADMINISTRATOR, text: "Администратор" },
  { value: roles.USER, text: "Пользователь" },
];

export default {
  login(token) {
    storage.set("token", token);

    const { exp } = decode(token);
    storage.set("expires", exp);
  },
  logout() {
    storage.remove("token");
    storage.remove("user");
    storage.remove("expires");
  },
  loggedIn() {
    const tokenExist = !!storage.get("token");
    const tokenExpired = storage.get("expires") < Date.now() / 1000;

    return tokenExist && !tokenExpired;
  },
  setUser(user) {
    storage.set("user", user);
  },
  getToken() {
    return storage.get("token");
  },
  getRefreshToken() {
    return storage.get("refreshToken");
  },
  getUser() {
    if (!storage.get("user")) {
      return null;
    }
    return storage.get("user");
  },
  isAdmin() {
    const user = this.getUser();
    if (!user) {
      return false;
    }
    return user.role === roles.ADMINISTRATOR;
  },
  roles,
  rolesDict,
};
