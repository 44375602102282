export default {
  data() {
    return {
      menuPropsClose: {
        closeOnClick: false,
      },
    };
  },
  methods: {
    closeMenuPropsOnClick(value) {
      this.menuPropsClose.closeOnClick = value;
    },
  },
};
