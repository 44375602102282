<template>
  <v-hover
    v-slot:default="{ hover }"
    open-delay="50"
  >
    <v-icon
      :color="hover ? 'black' : 'grey'"
      :title="subscription"
      medium
      @click="eventClick"
      v-text="'mdi-plus'"
    />
  </v-hover>
</template>

<script>
export default {
  props: {
    subscription: {
      type: String,
      default: "Добавить",
    },
  },
  methods: {
    eventClick() {
      this.$emit("click");
    },
  },
};
</script>
