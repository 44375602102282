export default class {
  constructor() {
    this.state = {
      items: [],
      pagination: {
        page: 1,
        rowsPerPage: 8,
      },
      columns: [],
      sort: [],
      filter: null,
      filters: [],
      loading: false,
      actions: [],
      defaultFilter: null,
    };

    this.getters = {
      items(state) {
        return state.items;
      },
      pagination(state) {
        return state.pagination;
      },
      columns(state) {
        return state.columns;
      },
      sort(state) {
        return state.sort;
      },
      filter(state) {
        return state.filter;
      },
      filters(state) {
        return state.filters;
      },
      loading(state) {
        return state.loading;
      },
      allActionsRegistered(state) {
        if (
          state.actions.includes("pagging") ||
          state.actions.includes("filter") ||
          state.actions.includes("rowsCount")
        ) {
          return true;
        } else {
          return false;
        }
      },
      defaultFilter(state) {
        return state.defaultFilter;
      },
    };

    this.mutations = {
      setItems(state, items) {
        state.items = items;
      },
      setPagination(state, pagination) {
        state.pagination = pagination;
      },
      setColumns(state, columns) {
        state.columns = columns;
      },
      setSort(state, sort) {
        state.sort = sort;
      },
      setFilter(state, filter) {
        state.filter = filter;
        state.page = 1;
      },
      setFilters(state, filters) {
        state.filters = filters;
      },
      clearFilterValue(state, filterValue) {
        if (
          filterValue.type === "decimal" ||
          filterValue.type === "int" ||
          filterValue.type === "date"
        ) {
          filterValue.valueFrom = null;
          filterValue.valueTo = null;
        } else {
          filterValue.value = null;
        }
        state.pagination.page = 1;
      },
      setLoading(state, loading) {
        state.loading = loading;
      },
      registerAction(state, action) {
        state.actions.push(action);
      },
      setDefaultFilter(state, value) {
        return state.defaultFilter = value;
      },
    };

    this.actions = {
      changeFilter({ commit }, filterItem) {
        commit("clearFilterValue", filterItem);
      },
    };

  }
}
