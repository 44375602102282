import http from "./http.js";

async function getFactory(resource, id) {
  const response = await http.get(`isg-logistics/${resource}/${id === "0" ? "new" : id}`);
  return response;
}


async function createFactory(resource, factory) {
  const response = await http.post(`isg-logistics/${resource}`, factory);
  return response;
}

async function saveFactoryAttachments(resource, id, attachments) {
  const response = await http.post(
    `isg-logistics/${resource}/${id}/attachments`,
    attachments,
  );
  return response;
}


export {
  getFactory,
  createFactory,
  saveFactoryAttachments,
};