<template>
  <v-card>
    <v-card-title class="pb-0">
      <h1 class="headline">
        Редактирование Контактного лица
      </h1>
    </v-card-title>
    <v-card-text class="pt-2">
      <v-form v-model="isValid">
        <v-layout column>
          <v-layout row>
            <v-flex
              xs6
              class="mt-3 mb-3"
            >
              <TitleTextField
                v-model="name"
                :required="true"
                :rules="[rules.required, rules.min]"
                label="Имя*"
                class="mx-2 mb-3"
                @input="isChanged = true"
              />
            </v-flex>
            <v-flex
              xs6
              class="mt-3 mb-3"
            >
              <TitleTextField
                v-model="surname"
                :required="true"
                :rules="[rules.required, rules.min]"
                label="Фамилия*"
                class="mx-2 mb-3"
                @input="isChanged = true"
              />
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex xs6>
              <EnumSelect
                v-model="role"
                class="mx-2 mb-3"
                label="Роль*"
                :return-object="true"
                resource="contact-person-roles"
                :outline="true"
                :height-field="44"
                @input="isChanged = true"
              />
            </v-flex>
            <v-flex xs6>
              <app-reference
                v-model="position"
                class="mx-2 mb-3"
                resource="positions"
                component-name="Position"
                label="Должность"
                :outline="true"
                :height-field="44"
                @input="isChanged = true"
              />
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex xs6>
              <EditableList
                v-model="phones"
                class="mx-2 mb-3"
                label="Телефон"
                :mask="getMask('phone')"
                :hint="hint"
                filter="phoneInternal"
                outline
                height="44"
                title="Редактирование номеров телефонов"
                data-test="phone"
                @change="changePhones"
              />
            </v-flex>
            <v-flex xs6>
              <EditableList
                v-model="emails"
                class="mx-2 mb-3"
                label="Email"
                :rules="[rules.email]"
                outline
                height="44"
                title="Редактирование e-mail"
                data-test="email"
                @change="changeEmails"
              />
            </v-flex>
          </v-layout>

          <v-layout row>
            <v-flex xs12>
              <EditableList
                v-model="skypes"
                class="mx-2 mb-3"
                label="Skype"
                :rules="[rules.skype]"
                outline
                height="44"
                title="Редактирование Skype"
                data-test="skype"
                @change="changeSkype"
              />
            </v-flex>
          </v-layout>
        </v-layout>

        <v-layout class="mt-3">
          <v-btn
            small
            color="primary"
            class="mr-2"
            :disabled="!(isValid && isChanged) || isRemoved || loading"
            @click="submit(false)"
          >
            Сохранить
          </v-btn>
          <v-btn
            small
            @click="cancel"
          >
            Отмена
          </v-btn>
        </v-layout>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { NestedEditorsEventBus } from "../../components/nested-editors/nested-editors-event-bus.js";
import ConfirmMixin from "../../mixins/confirm-cancel-changes.js";
import TitleTextField from "../../components/TitleTextField.vue";
import EnumSelect from "../../components/EnumSelect.vue";
import EditableList from "../../components/EditableList.vue";
import * as contactPersonsApi from "../../backend/contactPersonsApi.js";

export default {
  components: {
    TitleTextField,
    EnumSelect,
    EditableList,
  },
  mixins: [ConfirmMixin],
  data() {
    return {
      isValid: false,
      isChanged: false,
      rules: {
        empty: (v) => !!v || "",
        required: (v) => !!v || "Поле обязательно к заполнению",
        min: (v) =>
          v.length >= 2 || "Поле должно содержать не менее 2-х символов",
        email: (v) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || v === "" || "Не корректно введен e-mail.";
        },
        skype: (v) => {
          const pattern = /^(^[a-zA-Z][a-zA-Z0-9_.,-]{5,31})$/;
          return pattern.test(v) || v === "" || "Не корректно введен Skype.";
        },
      },
      resource: "contact-persons",
      addMessage: "Контактное лицо добавлено",
      editMessage: "Контактное лицо изменено",
      name: "",
      surname: "",
      role: { id: 2, title: "КОНТАКТНОЕ ЛИЦО" },
      position: null,
      phones: [],
      emails: [],
      skypes: [],
      isRemoved: false,
      hint: "Номер телефона уже добавлен",
      loading: false,
    };
  },
  computed: {
    isModal() {
      let parent = this.$parent;
      while (parent) {
        if (parent.$options._componentTag === "NestedEditors") {
          return true;
        }
        parent = parent.$parent;
      }
      return false;
    },
  },
  mounted() {
    NestedEditorsEventBus.$emit("modal-editor-mounted");
  },
  methods: {
    changePhones(val) {
      this.isChanged = true;
      this.phones = val;
    },
    changeEmails(val) {
      this.isChanged = true;
      this.emails = val;
    },
    changeSkype(val) {
      this.isChanged = true;
      this.skypes = val;
    },
    getMask(controlType) {
      switch (controlType) {
        case "phone":
          return "+##(###)###-##-## *#####";
        default:
          return "";
      }
    },
    async submit() {
      const contactPerson = {
        name: this.name,
        surname: this.surname,
        phones: this.phones,
        emails: this.emails,
        skypes: this.skypes,
        positionId: this.position ? this.position.id : null,
        roleId: this.role.id,
      };

      this.loading = true;
      const res = await contactPersonsApi.createContactPerson(
        this.resource,
        contactPerson,
      );
      this.showInfo(this.addMessage);
      this.isChanged = false;
      this.loading = false;
      NestedEditorsEventBus.$emit("create", res.data);
    },
    cancel() {
      NestedEditorsEventBus.$emit("close");
    },
    async saveAndClose() {
      await this.submit();
    },
    resetLoaders() {
      this.loading = false;
    },
  },
};
</script>
