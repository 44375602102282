export default [
  {
    title: "Поставки",
    name: "shippings",
    routeName: "shippings",
    active: true,
  },
  {
    title: "Черновики",
    name: "drafts",
    routeName: "drafts",
    active: true,
  },
  {
    title: "Отгрузки",
    name: "release-orders",
    routeName: "releaseOrders",
    active: true,
  },
  {
    title: "Финансы",
    name: "finance",
    routeName: "finance",
    active: true,
  },
];