<template>
  <div>
    <h1 class="headline mb-2">
      Редактирование места погрузки
    </h1>
    <v-form v-model="isValid">
      <v-layout column>
        <v-flex
          xs4
          class="mx-1 mb-3"
        >
          <app-reference
            v-model="address"
            component-name="Address"
            :rules="[rules.empty]"
            :required="true"
            label="Адрес"
            resource="addresses"
            @input="isChanged = true"
          />
          <v-text-field
            v-model="timetable"
            :rules="[rules.required, rules.min]"
            :required="true"
            label="График работы"
            class="mt-4"
            outlined
            dense
            @input="isChanged = true"
          />
          <MultipleReferenceCompact
            v-model="contactPersons"
            :rules="[rules.empty]"
            :required="true"
            label="Контактное лицо"
            resource="contact-persons"
            component-name="ContactPerson"
            :disabled-add="false"
            @input="isChanged = true"
          />
        </v-flex>
      </v-layout>

      <v-btn
        small
        color="primary"
        :disabled="!(isValid && isChanged)"
        class="mr-2"
        data-test="loadingPlaceAdd"
        @click="submit"
      >
        Ок
      </v-btn>
      <v-btn
        small
        data-test="loadingPlaceEditorCancel"
        @click="$emit('cancel')"
      >
        Отмена
      </v-btn>
    </v-form>
  </div>
</template>

<script>
import MultipleReferenceCompact from "../../components/MultipleReferenceCompact.vue";

export default {
  components: {
    MultipleReferenceCompact,
  },
  props: ["value"],
  data() {
    return {
      isValid: false,
      isChanged: false,
      rules: {
        empty: v => !!v || "",
        required: v => !!v || "Поле обязательно к заполнению",
        min: v => v.length >= 3 || "Поле должно содержать не менее 3-х символов",
      },
      id: this.value ? this.value.id : 0,
      address: this.value ? this.value.address : null,
      timetable: this.value ? this.value.timetable : "",
      contactPersons: this.value ? this.value.contactPersons : null,
    };
  },
  watch: {
    items(val) {
      this.id = val.id;
      this.address = val.address;
      this.timetable = val.timetable;
      this.contactPersons = val.contactPersons;
    },
  },
  methods: {
    submit() {
      const item = {
        id: this.id ? this.id : 0,
        address: this.address,
        timetable: this.timetable,
        contactPersons: this.contactPersons,
      };
      if (this.value) {
        this.$emit("change", item);
      } else {
        this.$emit("create", item);
      }
    },
  },
};
</script>
