import Vue from "vue";
import Vuex from "vuex";
import enums from "./modules/enums.js";

import shippings from "../views/shippings/shippings.js";
import shippingDrafts from "../views/drafts/shipping-drafts.js";
import users from "../views/admin-panel/users/users.js";
import newsPosts from "../views/admin-panel/posts/news/newsPosts.js";
import termsPosts from "../views/admin-panel/posts/terms/termsPosts.js";
import warehousesPosts from "../views/admin-panel/posts/warehouses/warehousesPost.js";
import news from "../views/news/news";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    enums,
    shippings,
    shippingDrafts,
    users,
    newsPosts,
    termsPosts,
    warehousesPosts,
    news,
  },
  state: {},
  mutations: {},
  actions: {},
});
