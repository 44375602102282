<template>
  <v-menu
    v-model="menu"
    :disabled="disabled"
    :close-on-content-click="false"
    :close-on-click="false"
    top
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        :loading="readFilesLoading"
        :disabled="disabled || disabledUpload"
        class="ma-0"
        v-on="on"
        @click="showFileDialog"
      >
        <v-icon>mdi-arrow-collapse-up</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="pb-2">
        <h2 class="title font-weight-bold">
          Загрузка файлов
        </h2>
      </v-card-title>
      <v-card-text
        :class="['py-0', `${files.length > 6 ? 'document-list' : ''}`]"
      >
        <v-layout column>
          <v-layout
            v-for="file in files"
            :key="file.sourceName"
          >
            <v-flex
              xs6
              class="px-1"
            >
              <v-text-field
                v-model="file.originName"
                label="Имя вложения"
                hide-details
                @change="changeOriginName"
              />
            </v-flex>
            <v-flex
              xs6
              class="px-1"
            >
              <v-text-field
                disabled
                hide-details
                label="Имя файла"
                :value="file.sourceName"
              />
            </v-flex>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          text
          color="primary"
          :loading="uploadFilesLoading"
          @click="upload"
        >
          Ок
        </v-btn>
        <v-btn
          text
          @click="close"
        >
          Отмена
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script>
import upload from "./upload.js";
import * as attachmentsApi from "../../backend/attachmentsApi.js";

export default {
  props: [
    "disabled",
    "resource",
    "disabledSender",
    "disabledUpload",
    "disabledUploadToServer",
    "type",
    "clientId",
  ],
  data() {
    return {
      menu: false,
      files: [],
      readFilesLoading: false,
      uploadFilesLoading: false,
    };
  },
  methods: {
    showFileDialog() {
      upload().then(files => {
        this.files = this.files.concat(files);
        this.files.forEach(f => (f.title = f.originName));
        this.menu = true;
      });
    },
    async upload() {
      if (this.disabledUploadToServer || this.resource.includes("shipping-drafts")) {
        this.$emit("upload", this.files);
        this.menu = false;
        this.files = [];
      } else {
        this.uploadFilesLoading = true;

        const res = await attachmentsApi.uploadAttachment(
          this.files,
          this.clientId,
        );
        this.uploadFilesLoading = false;
        this.menu = false;
        this.files = [];
        this.showInfo("Файл добавлен");
        this.$emit("upload", res);
      }
    },
    close() {
      this.menu = false;
      this.files = [];
    },
    changeOriginName(newValue) {
      this.files.forEach(file => {
        if (
          file.originName === newValue &&
          file.originName != file.sourceName
        ) {
          const splitSourceName = file.sourceName.split(".");
          const extention = splitSourceName[splitSourceName.length - 1];
          file.originName = newValue.includes(extention)
            ? newValue
            : `${newValue}.${extention}`;
        }
      });
    },
  },
};
</script>
<style>
.document-list {
  height: 290px;
  overflow-y: auto;
}
</style>
