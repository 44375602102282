<template>
  <v-tooltip
    top
    :disabled="!tooltip"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="formatedText"
        class="mx-2"
        :required="required"
        :rules="rules"
        :label="label"
        :hide-details="!rules || disabled || readonly"
        autofocus
        outlined
        dense
        :readonly="readonly"
        :disabled="disabled"
        @input="changeTextValue($event)"
        v-on="on"
      />
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: [
    "value",
    "inputLanguage",
    "required",
    "rules",
    "label",
    "tooltip",
    "readonly",
    "height",
    "disabled",
  ],
  data() {
    return {
      formatedText: "",
    };
  },
  watch: {
    value: {
      handler(val) {
        this.formatedText = val;
      },
      immediate: true,
    },
  },
  mounted() {
    const input = this.$children[0].$children[0].$refs.input;
    if (!input) {
      return;
    }
    input.addEventListener("input", () => {
      let replaceRegex = null;
      if (this.inputLanguage === "cyrillic") {
        replaceRegex = /[^А-ЯЁЇІЄҐ,0-9,.-\s/]/gim;
      } else if (this.inputLanguage === "latin") {
        replaceRegex = /[^A-Z,0-9,.-\s/]/gim;
      }

      let formattedValue = input.value;
      if (replaceRegex) {
        formattedValue = input.value.replace(replaceRegex, "");
      }
      formattedValue = formattedValue.toUpperCase();

      let position = input.selectionStart;
      if (input.value.length !== formattedValue.length) {
        position--;
      }
      input.value = formattedValue;
      if (position) {
        setCaretPosition(input, position);
      }
      input.value = formattedValue;

      this.$children[0].$data.lazyValue = formattedValue;
      this.$children[0].$emit("input", formattedValue);

      function setCaretPosition(el, caretPos) {
        if (el != null) {
          if (el.createTextRange) {
            var range = el.createTextRange();
            range.move("character", caretPos);
            range.select();
          } else {
            if (el.selectionStart) {
              el.focus();
              el.setSelectionRange(caretPos, caretPos);
            } else el.focus();
          }
        }
      }
    });
  },
  methods: {
    changeTextValue(value) {
      if (!value) {
        return;
      }
      this.$emit(
        "input",
        this.$options.filters.trimSpaces(value).toUpperCase(),
      );
    },
  },
};
</script>
