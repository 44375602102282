<template>
  <v-menu>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        color="secondary"
        v-bind="attrs"
        class="pa-0 pt-3"
        v-on="on"
      >
        Администрирование
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-for="item in adminMenu"
        :key="item.routeName"
        :to="{ name: item.routeName }"
        no-action
        exact
        active-class="active"
        class="px-0"
        :style="`width: 250px; max-width: 250px`"
      >
        <v-list-item-title
          class="text-uppercase text-center primary--text"
          v-text="item.title"
        />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import adminMenu from "@/helpers/adminMenu.js";

export default {
  data: () => ({
    adminMenu,
  }),
};
</script>
