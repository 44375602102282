import http from "./http.js";

async function searchReference(resource, search) {
  const response = await http.get(
    `isg-logistics/${resource}/references?searchTerm=${search}`,
  );
  return response.data.map(i => {
    return {
      text: i.title,
      value: i.id,
    };
  });
}

async function getReference(resource, id) {
  const response = await http.get(`isg-logistics/${resource}/references/${id}`);
  return {
    id: response.data.id,
    title: response.data.title,
  };
}

async function getAllReferencies(resource) {
  const response = await http.get(`isg-logistics/${resource}/references`);
  return response.data.map(i => {
    return {
      text: i.title,
      value: i.id,
    };
  });
}

async function getReferencies(resource, filter) {
  const response = await http.get(`isg-logistics/${resource}/references`, { params: filter });

  let responseData = [];
  if (Array.isArray(response.data) && response.data.length > 100) {
    responseData = response.data.splice(0, 100);
    return responseData.map(i => {
      return {
        text: i.title,
        value: i.id,
      };
    });
  } else if (Array.isArray(response.data) && response.data.length <= 100) {
    responseData = response.data;
    return responseData.map(i => {
      return {
        text: i.title,
        value: i.id,
      };
    });
  }

  return responseData;
}

async function getReferenceList(resource, id) {
  const response = await http.get(`isg-logistics/${resource}/${id}`);
  return response;
}

async function getTinyReferenceList(resource, id, info) {
  const response = await http.get(
    `isg-logistics/${resource}/${id}/${info}`,
  );
  return response;
}


export {
  searchReference,
  getReference,
  getAllReferencies,
  getReferencies,
  getReferenceList,
  getTinyReferenceList,
};
