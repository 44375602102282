<template>
  <v-dialog
    :value="show"
    persistent
    width="1000"
  >
    <v-tabs v-model="activeModalTab">
      <v-tab
        v-for="(editor, index) in editors"
        :key="index"
        :disabled="index+1 !== editors.length"
      >
        {{ editor.title }}
      </v-tab>
      <v-tab-item
        v-for="(editor, index) in editors"
        :key="index"
      >
        <component
          :is="editor.component"
          :id="editor.id"
          v-bind="editor.params"
          @create="push"
        />
      </v-tab-item>
    </v-tabs>
  </v-dialog>
</template>

<script>
import { NestedEditorsEventBus } from "./nested-editors-event-bus.js";
export default {
  data() {
    return {
      activeModalTab: null,
      editors: [],
    };
  },
  computed: {
    show() {
      return this.editors.length !== 0;
    },
  },
  created() {
    NestedEditorsEventBus.$on("open", (activator) => this.add(activator));
    NestedEditorsEventBus.$on("close", () => this.remove());
    NestedEditorsEventBus.$on("create", (createdId) => this.push(createdId));
    NestedEditorsEventBus.$on(
      "modal-editor-mounted",
      () => (this.activeModalTab = this.editors.length - 1),
    );
  },
  beforeDestroy() {
    NestedEditorsEventBus.$off();
  },
  methods: {
    add(activator) {
      this.editors.push({
        title: activator.title,
        activatorUid: activator.uid,
        component: activator.component,
        params: activator.params,
        id: activator.id,
      });
      this.activeModalTab = this.editors.length - 1;
    },
    remove() {
      this.editors.pop();
      this.activeModalTab = this.editors.length - 1;
    },
    push(createdId) {
      const editor = this.editors.pop();
      NestedEditorsEventBus.$emit("create-" + editor.activatorUid, createdId);
      this.activeModalTab = this.editors.length - 1;
    },
  },
};
</script>
<style>
.v-tabs__container .v-tabs__div {
  line-height: 1;
}
</style>
