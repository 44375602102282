<template>
  <v-autocomplete
    v-model="selected"
    :class="{ 'red lighten-4': selected && selected.isRemoved }"
    class="append-add-icon"
    :items="items"
    :loading="loading || loadingFetchAll"
    :search-input.sync="search"
    :rules="rules"
    :clearable="clearable"
    :label="label"
    return-object
    hide-details
    hide-selected
    :disabled="isDisabled || loadingFetchAll"
    outlined
    no-filter
    no-data-text="Данные не выбраны"
    clear-icon="mdi-close"
    :menu-props="menuPropsClose"
    :error="error"
    dense
    :readonly="readonly"
    prepend-inner-icon="mdi-folder-outline"
    @change="handleItemChange"
    @click:clear="clear"
    @click="closeMenuPropsOnClick(false)"
  >
    <template v-slot:append>
      <AddReferenceBtn
        v-if="!disableAdd"
        @click="createNew(false)"
      />
      <v-icon
        v-if="editBtn"
        :title="'Редактировать'"
        :disabled="disabledEditBtn"
        @click="createNew(true)"
      >
        mdi-pencil
      </v-icon>
    </template>
  </v-autocomplete>
</template>
<script>
import AddReferenceBtn from "./AddReferenceBtn.vue";
import CloseDropdownList from "../mixins/close-dropdown-list.js";
import { NestedEditorsEventBus } from "./nested-editors/nested-editors-event-bus.js";
import * as referenceApi from "../backend/referenceApi.js";

export default {
  components: { AddReferenceBtn },
  mixins: [CloseDropdownList],
  props: [
    "value",
    "label",
    "rules",
    "resource",
    "parentId",
    "parentFilter",
    "dataRestored",
    "disabled",
    "disableAdd",
    "parentLoading",
    "withoutParentLock",
    "withoutClearable",
    "componentName",
    "filterName",
    "filterValue",
    "outline",
    "editBtn",
    "error",
    "disabledEditBtn",
    "scrollValue",
    "timeout",
    "readonly",
  ],
  data() {
    return {
      selected: null,
      search: null,
      items: [],
      loading: false,
      loadingFetchAll: false,
    };
  },
  computed: {
    isDisabled() {
      if (this.withoutParentLock) {
        return this.disabled || this.parentLoading;
      }
      return (
        this.disabled ||
        (this.parentFilter && !this.parentIdValue) ||
        this.parentLoading
      );
    },
    watchOnParent() {
      return this.dataRestored;
    },
    parentIdValue() {
      return this.parentId ? this.parentId : null;
    },
    clearable() {
      return !this.withoutClearable;
    },
    isMenuActive() {
      return this.$children[0].$data.isMenuActive;
    },
  },
  watch: {
    value: {
      handler() {
        if (!this.value) {
          this.selected = null;
          this.search = null;
          this.items = [];
          return;
        }
        if (
          this.selected &&
          this.value.id === this.selected.value &&
          this.value.title === this.search
        ) {
          return;
        }
        const selectedItem = this.items.find(i => i.value === this.value.id);
        if (selectedItem) {
          this.selected = selectedItem;
          this.search = selectedItem.text;
        } else {
          this.setSelectedElement(this.value);
        }
      },
      deep: true,
    },
    async search() {
      if (!this.search && this.isMenuActive) {
        this.closeMenuPropsOnClick(false);
        this.selected = null;
        await this.fetchAll();
        return;
      }
      if (this.loading) {
        return;
      }

      if (this.selected && this.selected.text === this.search) {
        return;
      }
      setTimeout(async() => {
        if (!this.search) {
          this.selected = null;
          this.items = [];
          return;
        }
        if (this.loading) {
          return;
        }
        this.loading = true;
        await this.searchReference();
        this.loading = false;
        this.$children[0].onFocus();
      }, this.timeout || 1500);
    },
    parentId(newId, oldId) {
      if (!this.watchOnParent) {
        return;
      }
      if (oldId && newId !== oldId) {
        this.selected = null;
        this.items = [];
      }
    },
    scrollValue() {
      if (!this.scrollValue) {
        return;
      }
      closeActivList(this.$children[0]);

      function closeActivList(value) {
        if (value.isActive) {
          value.isActive = false;
        }
        if (value.$children) {
          value.$children.forEach(v => closeActivList(v));
        }
      }
    },
  },
  created() {
    if (this.value) {
      this.setSelectedElement(this.value);
    }
    NestedEditorsEventBus.$on("create-" + this._uid, createdId => {
      this.getReference(createdId);
    });
  },
  mounted() {
    this.$watch(
      () => {
        return this.isMenuActive;
      },
      async newVal => {
        if (
          (newVal && this.selected?.text === this.search) ||
          (newVal && !this.selected && !this.search)
        ) {
          this.closeMenuPropsOnClick(false);
          await this.fetchAll();
          this.$children[0].onFocus();
        } else {
          this.clear();
        }
      },
    );
  },
  methods: {
    handleItemChange() {
      this.$emit(
        "input",
        this.selected
          ? { id: this.selected.value, title: this.selected.text }
          : null,
      );
    },
    async fetchAll() {
      this.loadingFetchAll = true;
      const filter = new Object();
      if (this.parentFilter && this.parentIdValue) {
        filter[this.parentFilter] = this.parentIdValue;
      }
      if (this.filterName && this.filterValue !== undefined) {
        filter[this.filterName] = this.filterValue;
      }

      this.items = [];
      this.items = await referenceApi.getReferencies(this.resource, filter);

      this.loadingFetchAll = false;
    },
    clear() {
      this.items = [];
      if (this.selected) {
        this.items.push(this.selected);
      }
    },
    createNew(isNewValue) {
      this.closeMenuPropsOnClick(true);
      this.$emit("click");
      NestedEditorsEventBus.$emit("open", {
        title: this.label,
        uid: this._uid,
        component: this.componentName,
        params: isNewValue ? this.selected : null,
        id: isNewValue ? this.selected.value : "0",
      });
    },
    setSelectedElement(value) {
      const newItem = {
        text: value.title,
        value: value.id,
        isRemoved: value.isRemoved,
      };
      this.selected = newItem;
      const item = this.items.find(i => i.value === newItem.value);
      if (item) {
        this.items.forEach(i => {
          if (i.value === newItem.value) {
            i.text = newItem.text;
          }
        });
      } else {
        this.items.push(this.selected);
      }
    },
    async getReference(id) {
      this.loading = true;
      const res = await referenceApi.getReference(this.resource, id);
      this.loading = false;
      await this.setSelectedElement(res);
      this.handleItemChange();
    },
    async searchReference() {
      const filter = new Object();
      filter.searchTerm = this.search;
      if (this.parentFilter && this.parentIdValue) {
        filter[this.parentFilter] = this.parentIdValue;
      }
      if (!this.filterName && this.filterValue !== undefined) {
        filter[this.filterName] = this.filterValue;
      }
      this.items = [];
      this.items = await referenceApi.getReferencies(this.resource, filter);
    },
  },
};
</script>
<style>
.append-add-icon .v-input__icon--append .v-icon {
  -webkit-transform: none !important;
  transform: none !important;
}
.append-add-icon
  .v-input__control
  .v-input__slot
  .v-select__slot
  .v-input__append-inner
  .v-input__icon
  .v-icon {
  color: grey !important;
}
.append-add-icon
  .v-input__control
  .v-input__slot
  .v-select__slot
  .v-input__append-inner
  .v-input__icon
  .v-icon:hover {
  color: #424242 !important;
}
</style>
