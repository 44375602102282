<template>
  <v-app-bar color="primary">
    <v-toolbar-title class="white--text">
      <div class="font-weight-bold title">
        Proimport online
      </div>
      <div
        v-if="isAdmin"
        class="font-weight-medium caption"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              color="secondary"
              v-bind="attrs"
              @click="$router.push({name: 'user'})"
              v-on="on"
            >
              <v-icon>
                mdi-account-circle
              </v-icon>
            </v-btn>
          </template>
          <span>Данные Администратора</span>
        </v-tooltip>
        <span class="ml-1">{{ user.name }}</span>
      </div>
      <div
        v-else
        class="font-weight-medium caption"
      >
        <span>{{ user.clientTitle }}</span>
        <span class="pl-2 pr-1">/</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              icon
              color="secondary"
              v-bind="attrs"
              @click="$router.push({name: 'user'})"
              v-on="on"
            >
              <v-icon>
                mdi-account-circle
              </v-icon>
            </v-btn>
          </template>
          <span>Данные Пользователя</span>
        </v-tooltip>
        {{ user.contactPersonTitle }}
      </div>
    </v-toolbar-title>
    <v-spacer />
    <v-row class="d-flex justify-end">
      <v-spacer />
      <v-col
        v-if="isAdmin"
        class="pa-0"
      >
        <AdminMenu />
      </v-col>
      <v-col
        v-for="item in menuItems"
        v-else
        :key="item.routeName"
        :style="`width: ${item.width}px; max-width: ${item.width}px`"
        class="pa-0"
      >
        <v-list-item
          :key="item.routeName"
          :to="{ name: item.routeName }"
          no-action
          exact
          active-class="active"
          class="px-0"
        >
          <v-list-item-title
            class="white--text text-uppercase text-center"
            v-text="item.title"
          />
        </v-list-item>
      </v-col>
      <slot name="logout" />
    </v-row>
  </v-app-bar>
</template>

<script>
import menuItems from "@/helpers/mainMenu.js";
import AdminMenu from "../views/admin-panel/AdminMenu.vue";
import auth from "../auth.js";

export default {
  components: {
    AdminMenu,
  },
  data: () => ({
    menuItems,
    user: {},
  }),
  computed: {
    isAdmin() {
      return auth.isAdmin();
    },
  },
  created() {
    this.user = auth.getUser();
  },
};
</script>

<style>
.active {
  opacity: 0.6;
}
</style>
