class Storage {
  constructor(storage) {
    this.storage = storage;
  }

  get(key) {
    const state = this.storage.getItem(key);
    return JSON.parse(state);
  }

  set(key, body) {
    this.storage.setItem(key, JSON.stringify(body));
  }

  remove(key) {
    this.storage.removeItem(key);
  }
}

export default Storage;
