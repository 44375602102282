<template>
  <v-card :flat="isModal ? true : false">
    <v-card-title class="pb-0">
      <h1 class="headline">
        Редактирование Города
      </h1>
    </v-card-title>
    <v-card-text class="pt-2">
      <v-form v-model="isValid">
        <v-layout column>
          <v-flex xs12>
            <v-layout row>
              <v-flex
                xs6
                class="mt-3"
              >
                <TitleTextField
                  v-model="entity.title"
                  :required="true"
                  :input-language="inputLanguage"
                  :rules="[
                    v => !!v || 'Наименование обязательно к заполнению',
                    v =>
                      v.length >= 3 ||
                      'Наименование должно содержать не менее 3-х символов'
                  ]"
                  label="Наименование*"
                  class="mx-2"
                  @input="entityChange"
                />
              </v-flex>
              <v-flex
                xs6
                class="mt-3"
              >
                <CountryReference
                  v-model="entity.country"
                  :rules="[v => !!v || '']"
                  :clearable="true"
                  label="Страна*"
                  class="mx-2"
                  @input="countryChange"
                />
              </v-flex>
            </v-layout>
            <v-layout row>
              <v-flex
                xs6
                class="mt-3"
              >
                <RegionReference
                  v-model="entity.region"
                  :rules="[v => !!v || '']"
                  :clearable="true"
                  :country-id="entity.country ? entity.country.id : 0"
                  label="Регион*"
                  class="mx-2"
                  @input="regionChange"
                />
              </v-flex>
              <v-flex
                xs6
                class="mt-3"
              >
                <DistrictReference
                  v-model="entity.district"
                  :clearable="true"
                  :country-id="entity.country ? entity.country.id : 0"
                  :region-id="entity.region ? entity.region.id : 0"
                  label="Область"
                  class="mx-2"
                  @input="entityChange"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout class="mt-3">
          <v-btn
            small
            color="primary"
            :disabled="!(isValid && isChanged) || entity.isRemoved || loading"
            class="mt-3 mr-2"
            @click="submit(false)"
          >
            Сохранить
          </v-btn>
          <v-btn
            small
            class="mt-3 mr-2"
            @click="cancel"
          >
            Отмена
          </v-btn>
        </v-layout>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "../../mixins/confirm-cancel-changes.js";
import RegionReference from "../../components/references/RegionReference.vue";
import CountryReference from "../../components/references/CountryReference.vue";
import { NestedEditorsEventBus } from "../../components/nested-editors/nested-editors-event-bus.js";
import DistrictReference from "../../components/references/DistrictReference.vue";
import * as citiesApi from "../../backend/citiesApi.js";
import TitleTextField from "../../components/TitleTextField.vue";

export default {
  components: {
    RegionReference,
    CountryReference,
    DistrictReference,
    TitleTextField,
  },
  mixins: [mixin],
  data() {
    return {
      entity: {
        id: 0,
        title: "",
        country: null,
        region: null,
        district: null,
      },
      entityRestored: false,
      resource: "cities",
      isValid: false,
      isChanged: false,
      loading: false,
    };
  },
  computed: {
    inputLanguage() {
      return this.entity.country ? this.entity.country.inputLanguage : "";
    },
    isModal() {
      let parent = this.$parent;
      while (parent) {
        if (parent.$options._componentTag === "NestedEditors") {
          return true;
        }
        parent = parent.$parent;
      }
      return false;
    },
    isConfirmed() {
      if (Object.prototype.hasOwnProperty.call(this.entity, "this.entity")) {
        return this.entity.isConfirmed;
      }
    },
  },
  mounted() {
    NestedEditorsEventBus.$emit("modal-editor-mounted");
  },
  methods: {
    entityChange() {
      if (!this.entityRestored && !this.isModal) {
        return;
      }

      this.isChanged = true;
    },
    regionChange(payload) {
      this.entity.country = null;
      if (payload && payload.country) {
        this.entity.country = payload.country;
      }
      this.entityChange();
    },
    countryChange(payload) {
      this.entity.region = null;
      if (payload && payload.region) {
        this.entity.region = payload.region;
      }
      this.entityChange();
    },
    async submit() {
      const entity = {
        id: this.entity.id,
        title: this.entity.title,
        regionId: this.entity.region.id,
        districtId: this.entity.district?.id,
      };

      this.loading = true;
      const res = await citiesApi.createCity(this.resource, entity);
      this.showInfo("Город добавлен");
      this.entity.id = res.data;
      NestedEditorsEventBus.$emit("create", this.entity.id);
      this.loading = false;
    },
    cancel() {
      NestedEditorsEventBus.$emit("close");
    },
    async saveAndClose() {
      await this.submit();
    },
    resetLoaders() {
      this.loading = false;
    },
  },
};
</script>
