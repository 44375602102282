<template>
  <v-autocomplete
    v-model="selected"
    class="autofill-chip-small icon-placement"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    :rules="rules"
    :label="label"
    clearable
    return-object
    hide-details
    hide-selected
    autocomplete="nope"
    no-filter
    no-data-text="Данные не выбраны"
    multiple
    chips
    deletable-chips
    small-chips
    outlined
    dense
    :menu-props="menuPropsClose"
    prepend-inner-icon="mdi-folder-outline"
    @change="handleChange"
    @click="closeMenuPropsOnClick(false)"
  >
    <template
      v-if="!disableAdd"
      v-slot:append
    >
      <AddReferenceBtn @click="createNew" />
    </template>
  </v-autocomplete>
</template>

<script>
import AddReferenceBtn from "./AddReferenceBtn.vue";
import CloseDropdownList from "../mixins/close-dropdown-list.js";
import { NestedEditorsEventBus } from "./nested-editors/nested-editors-event-bus.js";
import * as referenceApi from "../backend/referenceApi.js";

export default {
  components: { AddReferenceBtn },
  mixins: [CloseDropdownList],
  props: [
    "value",
    "resource",
    "componentName",
    "rules",
    "disableAdd",
    "label",
    "outline",
    "heightField",
  ],
  data() {
    return {
      selected: [],
      loading: false,
      search: null,
      itemsWithoutSelected: [],
    };
  },
  computed: {
    items: {
      set(value) {
        this.itemsWithoutSelected = value;
      },
      get() {
        return [...this.itemsWithoutSelected, ...this.selected];
      },
    },
  },
  watch: {
    async search() {
      if (!this.search) {
        await this.fetchAll();
        return;
      }

      this.loading = true;

      await this.searchReference();
    },
    value: {
      handler(val) {
        if (!val) {
          this.search = null;
          this.items = [];
          this.selected = [];
          return;
        } else {
          this.selected = val.map(i => {
            return { value: i.id, text: i.title };
          });
        }
      },
      immediate: true,
    },
  },
  created() {
    NestedEditorsEventBus.$on("create-" + this._uid, createdId => {
      this.getReference(createdId);
    });
  },
  mounted() {
    this.$watch(
      () => {
        return this.$children[0].$data.isMenuActive;
      },
      newVal => {
        if (newVal) {
          this.fetchAll();
        }
      },
    );
  },
  methods: {
    async fetchAll() {
      this.items = await referenceApi.getAllReferencies(this.resource);
      this.loading = false;
    },
    handleChange() {
      this.search = "";
      if (this.selected.length > 0) {
        this.$emit(
          "input",
          this.selected.map(i => {
            return { id: i.value, title: i.text };
          }),
        );
      } else {
        this.$emit("input", []);
      }
    },
    createNew() {
      this.closeMenuPropsOnClick(true);
      this.$emit("click");
      NestedEditorsEventBus.$emit("open", {
        title: this.label,
        uid: this._uid,
        component: this.componentName,
      });
    },
    setSelectedElement(value) {
      const newItem = {
        text: value.title,
        value: value.id,
      };
      this.items.push(newItem);
      if (!this.selected.includes(newItem)) {
        this.selected.push(newItem);
        this.handleChange();
      }
    },
    async searchReference() {
      this.items = await referenceApi.searchReference(
        this.resource,
        this.search,
      );
      if (this.selected.length > 0) {
        this.items = this.items.concat(this.selected);
      }
      this.loading = false;
    },
    async getReference(id) {
      const res = await referenceApi.getReference(this.resource, id);
      this.setSelectedElement(res);
    },
  },
};
</script>