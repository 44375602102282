import http from "./http.js";

async function getFactoryLoadingPlaceReference(factoryId, createdId) {
  const response = await http.get(
    `isg-logistics/factories/${factoryId}/loading-places/references/${createdId}`,
  );
  return { id: response.data.id, title: response.data.title };
}

async function getFactoryLoadingPlace(id) {
  const response = await http.get(`isg-logistics/factories/${id}/loading-places`);
  return response.data.map(i => {
    return {
      text: i.address.title,
      value: i.id,
      countryId: i.address.country.id,
    };
  });
}

async function searchFactoryLoadingPlace(id, queryParams) {
  const response = await http.get(
    `isg-logistics/factories/${id}/loading-places?searchTerm=${queryParams}`,
  );
  if (Array.isArray(response.data)) {
    return response.data.map(i => {
      return {
        text: i.address.title,
        value: i.id,
      };
    });
  } else {
    response.data = [];
    return response.data;
  }
}

async function getFactoryContactPerson(factoryId, loadingPlaceId) {
  const response = await http.get(
    `isg-logistics/factories/${factoryId}/loading-places/${loadingPlaceId}/contact-persons`,
  );
  return response.data.map(i => {
    return {
      text: i.title,
      value: i.id,
    };
  });
}

async function getContactPersonReference(resource, id) {
  const response = await http.get(`isg-logistics/${resource}/references/${id}`);
  return {
    id: response.data.id,
    title: response.data.title,
  };
}

async function createContactPersonReference(
    factoryId,
    loadingPlaceId,
    responseId,
) {
  const response = await http.post(
    `isg-logistics/factories/${factoryId}/loading-places/${loadingPlaceId}/contact-persons`,
    { value: responseId },
  );
  return response;
}

async function getFactoryReferencies(resource) {
  const response = await http.get(`isg-logistics/${resource}/references`);
  if (Array.isArray(response.data)) {
    return response.data.map(i => {
      return {
        text: i.title,
        value: i.id,
      };
    });
  } else {
    response.data = [];
    return response;
  }
}

async function getWarehouseContactPerson(id) {
  const response = await http.get(`isg-logistics/warehouses/${id}/contact-persons`);
  return response.data.length ? response.data.map(r => {
    return {
      text: r.fullName,
      value: r.id,
    };
  }) : [];
}

async function createWarehouseContactPersonReference(
    factoryId,
    loadingPlaceId,
    responseId,
) {
  const response = await http.post(
    `isg-logistics/factories/${factoryId}/loading-places/${loadingPlaceId}/contact-persons`,
    { value: responseId },
  );
  return response;
}

async function getLoadingPersonContact(id) {
  const response = await http.get(`contact-persons/${id}`);
  return response.data;
}

async function getFactory(id) {
  const response = await http.get(`isg-logistics/factories/${id}`);
  return response.data;
}

async function getSenderFactoryLoadingPlace(senderFactoryId, id) {
  const response = await http.get(
    `isg-logistics/factories/${senderFactoryId}/loading-places/${id}`,
  );
  return response.data;
}

async function updateNewClientAlias(id, alias) {
  const response = await http.patch(`isg-logistics/clients/${id}/aliases/add`, {}, {
    params: {
      alias,
    },
  });
  return response;
}

async function getClientAlias(id) {
  const response = await http.get(`isg-logistics/clients/${id}/aliases`);
  return response;
}

async function getWarehouse(id) {
  const response = await http.get(`isg-logistics/warehouses/${id}`);
  return response.data;
}

async function getAddress(id) {
  const response = await http.get(`isg-logistics/addresses/references/${id}`);
  return response.data;
}

async function getNewShipping() {
  const response = await http.get("isg-logistics/shippings/new");
  return response.data;
}

async function getNewShippingUkr() {
  const response = await http.get("isg-logistics/countries/rpc/by-title/Украина");
  return {
    id: response.data.id,
    title: response.data.title,
  };
}

async function getUkraine() {
  const response = await http.get("isg-logistics/countries/ukraine");
  return response.data;
}

async function getPackageType(id) {
  const response = await http.get(
    `isg-logistics/package-types/${id}`,
  );
  return response.data ? {
    packageType: {
      id: response.data.id,
      title: response.data.title,
    },
    length: response.data.length,
    width: response.data.width,
  } : null;
}

async function createDraftsAttachments(id, attachment) {
  const response = await http.post(`shipping-drafts/${id}/attachments`, attachment);
  return response;
}

async function getWarehouseReferenceAddress(id) {
  const response = await http.get(`isg-logistics/warehouses/references/addresses/${id}`);
  return response;
}

async function createShipping(entity) {
  const response = await http.post("isg-logistics/shippings", entity);
  return response.data;
}

async function deleteShippingDraft(id) {
  const response = await http.delete(`shipping-drafts/${id}`);
  return response;
}

async function createShippingDraft(entity) {
  const response = await http.post("shipping-drafts", entity);
  return response.data;
}

async function updateShippingDraft(id, entity) {
  const response = await http.put(`shipping-drafts/${id}`, entity);
  return response;
}

function createFactoryLoadingPlace(factoryId,
    addressId,
    timetable,
    contactPerson) {
  const response = http.post(`isg-logistics/factories/${factoryId}/loading-places`, {
    addressId,
    timetable,
    contactPersonsIds: contactPerson,
  });
  return response.data;
}
export {
  getFactoryLoadingPlaceReference,
  getFactoryLoadingPlace,
  searchFactoryLoadingPlace,
  getFactoryContactPerson,
  getContactPersonReference,
  createContactPersonReference,
  getFactoryReferencies,
  getWarehouseContactPerson,
  createWarehouseContactPersonReference,
  getLoadingPersonContact,
  getFactory,
  getSenderFactoryLoadingPlace,
  updateNewClientAlias,
  getWarehouse,
  getAddress,
  getNewShipping,
  getNewShippingUkr,
  getUkraine,
  getPackageType,
  createDraftsAttachments,
  getWarehouseReferenceAddress,
  createShipping,
  deleteShippingDraft,
  updateShippingDraft,
  createShippingDraft,
  getClientAlias,
  createFactoryLoadingPlace,
};
