import http from "./http.js";

async function getAttachment(id, clientId) {
  if (clientId) {
    const response = await http.get(`isg-logistics/shipping/${clientId}/attachments/${id}/download`,
      { responseType: "blob" },
    );
    return response;
  } else {
    const response = await http.get(`shipping-drafts/attachments/${id}/download`);
    return response;
  }
}

async function updateAttachment(draftId, id, title) {
  const response = await http.patch(`shipping-drafts/${draftId}/attachments/${id}/origin-name`, {}, {
    params: {
      originName: title,
    },
  });
  return response;
}

async function deleteAttachment(draftId, id) {
  const response = await http.delete(`shipping-drafts/${draftId}/attachments/${id}`);
  return response;
}

async function uploadAttachment(files, clientId) {
  const response = await http.post(`isg-logistics/shipping/${clientId}/attachments`, files);
  return response.data.attachments.map(a => {
    return { id: a.id, title: a.title };
  });
}

async function createAttachment(resource, email, theme, selected) {
  const response = await http.post(resource, {
    email,
    theme,
    attachments: selected,
  });
  return response;
}

export {
  getAttachment,
  updateAttachment,
  deleteAttachment,
  uploadAttachment,
  createAttachment,
};
