import http from "./http.js";

async function fetchAllDistricts(resource, queryParams) {
  const response = await http.get(`isg-logistics/${resource}/references?${queryParams}`);
  return response.data.map(i => {
    return {
      text: i.title,
      value: i.id,
      country: i.country ? {
        id: i.country.id,
        title: i.country.title,
        inputLanguage: i.country.inputLanguage,
      } : null,
      region: i.region ? {
        id: i.region.id,
        title: i.region.title,
      } : null,
    };
  });
}

async function getSearchReference(resource, search) {
  const response = await http.get(
    `isg-logistics/${resource}/references?searchTerm=${search}`,
  );
  if (Array.isArray(response.data)) {
    response.data.map(i => {
      return {
        text: i.title,
        value: i.id,
        country: {
          id: i.country.id,
          title: i.country.title,
          inputLanguage: i.country.inputLanguage,
        },
      };
    });
  } else {
    response.data = [];
    return response;
  }
}

async function getDistrictReference(resource, id) {
  const response = await http.get(`isg-logistics/${resource}/references/${id}`);
  return {
    id: response.data.id,
    title: response.data.title,
    country: {
      id: response.data.country.id,
      title: response.data.country.title,
      inputLanguage: response.data.country.inputLanguage,
    },
    region: {
      id: response.data.region.id,
      title: response.data.region.title,
    },
  };
}


async function createDistrict(resource, entity) {
  const response = await http.post(`isg-logistics/${resource}`, entity);
  return response;
}

async function getDistrict(resource, id) {
  const response = await http.get(`isg-logistics/${resource}/${id === "0" ? "new" : id}`);
  return {
    id: response.data.id,
    title: response.data.title,
    country: {
      id: response.data.country.id,
      title: response.data.country.title,
      inputLanguage: response.data.country.inputLanguage,
    },
    region: {
      id: response.data.region.id,
      title: response.data.region.title,
      inputLanguage: response.data.region.inputLanguage,
    },
    isRemoved: response.data.isRemoved,
    isConfirmed: response.data.isConfirmed,
  };
}

export {
  fetchAllDistricts,
  getDistrictReference,
  getSearchReference,
  createDistrict,
  getDistrict,
};