<template>
  <v-card :flat="isModal ? true : false">
    <v-card-title class="pb-0">
      <h1 class="headline">
        Редактирование Валюты
      </h1>
    </v-card-title>
    <v-card-text class="pt-2">
      <v-form v-model="isValid">
        <v-layout column>
          <v-flex xs12>
            <v-layout row>
              <v-flex
                xs12
                class="my-3"
              >
                <TitleTextField
                  v-model="entity.title"
                  :required="true"
                  :rules="[rules.required]"
                  :input-language="entity.inputLanguage"
                  label="Наименование*"
                  class="mx-2 mb-3"
                  @input="entityChange"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout class="mt-3">
          <v-layout v-if="isConfirmed === false">
            <v-btn
              small
              color="primary"
              data-test="saveAndCloseButton"
              class="mr-2"
              @click="saveAndClose"
            >
              Подтвердить
            </v-btn>
            <v-btn
              small
              data-test="cancelButton"
              @click="cancel"
            >
              Отмена
            </v-btn>
            <v-spacer />
          </v-layout>

          <v-layout v-else>
            <v-btn
              small
              color="primary"
              :disabled="!(isValid && isChanged) || entity.isRemoved || loading"
              data-test="saveButton"
              @click="submit(false)"
            >
              Сохранить
            </v-btn>
            <v-btn
              small
              data-test="cancelButton"
              @click="cancel"
            >
              Отмена
            </v-btn>
          </v-layout>
        </v-layout>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "../../mixins/confirm-cancel-changes.js";
import { NestedEditorsEventBus } from "../../components/nested-editors/nested-editors-event-bus.js";
import * as currenciesApi from "../../backend/currenciesApi";
import TitleTextField from "../../components/TitleTextField.vue";

export default {
  components: {
    TitleTextField,
  },
  mixins: [mixin],
  data() {
    return {
      entity: {
        title: 0,
      },
      resource: "currencies",
      isValid: false,
      isChanged: false,
      loading: false,
      rules: {
        required: (v) => !!v || "Поле обязательно к заполнению",
      },
    };
  },
  computed: {
    isModal() {
      let parent = this.$parent;
      while (parent) {
        if (parent.$options._componentTag === "NestedEditors") {
          return true;
        }
        parent = parent.$parent;
      }
      return false;
    },
    isConfirmed() {
      if (Object.prototype.hasOwnProperty.call(this.entity, "isConfirmed")) {
        return this.entity.isConfirmed;
      }
    },
  },
  mounted() {
    if (this.isModal) {
      NestedEditorsEventBus.$emit("modal-editor-mounted");
    }
  },
  methods: {
    entityChange() {
      this.isChanged = true;
    },
    async submit() {
      this.loading = true;
      const res = await currenciesApi.createCurrency(
        this.resource,
        this.entity,
      );
      this.showInfo("Валюта добавлена");
      this.entity.id = res.data;
      this.loading = false;
      NestedEditorsEventBus.$emit("create", this.entity.id);

      this.loading = false;
    },
    cancel() {
      NestedEditorsEventBus.$emit("close");
    },
    resetLoaders() {
      this.loading = false;
    },
  },
};
</script>
