<template>
  <v-card :flat="isModal ? true : false">
    <v-card-title class="pb-0">
      <h1 class="headline">
        Редактирование Региона
      </h1>
    </v-card-title>
    <v-card-text class="pt-2">
      <v-form v-model="isValid">
        <v-layout column>
          <v-flex xs12>
            <v-layout row>
              <v-flex
                xs4
                class="mt-3"
              >
                <TitleTextField
                  v-model="entity.title"
                  :required="true"
                  :input-language="inputLanguage"
                  :rules="[
                    v => !!v || 'Наименование обязательно к заполнению',
                    v =>
                      v.length >= 3 ||
                      'Наименование должно содержать не менее 3-х символов'
                  ]"
                  label="Наименование*"
                  class="mx-2"
                  @input="entityChange"
                />
              </v-flex>
              <v-flex
                xs4
                class="mt-3"
              >
                <TitleTextField
                  v-model="entity.code"
                  :required="true"
                  :input-language="inputLanguage"
                  label="Сокращение"
                  class="mx-2"
                  @input="entityChange"
                />
              </v-flex>
              <v-flex
                xs4
                class="mt-3"
              >
                <CountryReference
                  v-model="entity.country"
                  :rules="[v => !!v || '']"
                  :clearable="true"
                  label="Страна*"
                  class="mx-2"
                  @input="entityChange"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout class="mt-3">
          <v-btn
            small
            color="primary"
            :disabled="!(isValid && isChanged) || entity.isRemoved || loading"
            class="mt-3 mr-2"
            @click="submit(false)"
          >
            Сохранить
          </v-btn>
          <v-btn
            small
            class="mt-3 mr-2"
            @click="cancel"
          >
            Отмена
          </v-btn>
        </v-layout>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "../../mixins/confirm-cancel-changes.js";
import CountryReference from "../../components/references/CountryReference.vue";
import { NestedEditorsEventBus } from "../../components/nested-editors/nested-editors-event-bus.js";
import * as regionsApi from "../../backend/regionsApi.js";
import TitleTextField from "../../components/TitleTextField.vue";

export default {
  components: {
    CountryReference,
    TitleTextField,
  },
  mixins: [mixin],
  data() {
    return {
      entity: {
        id: 0,
        title: "",
        code: "",
        country: null,
      },
      entityRestored: false,
      resource: "regions",
      isValid: false,
      isChanged: false,
      loading: false,
    };
  },
  computed: {
    inputLanguage() {
      return this.entity.country ? this.entity.country.inputLanguage : "";
    },
    isModal() {
      let parent = this.$parent;
      while (parent) {
        if (parent.$options._componentTag === "NestedEditors") {
          return true;
        }
        parent = parent.$parent;
      }
      return false;
    },
    isConfirmed() {
      if (Object.prototype.hasOwnProperty.call(this.entity, "isConfirmed")) {
        return this.entity.isConfirmed;
      }
    },
  },
  mounted() {
    NestedEditorsEventBus.$emit("modal-editor-mounted");
  },
  methods: {
    entityChange() {
      if (!this.entityRestored && !this.isModal) {
        return;
      }

      this.isChanged = true;
    },
    async submit() {
      const entity = {
        id: this.entity.id,
        title: this.entity.title,
        code: this.entity.code,
        countryId: this.entity.country.id,
        isRemoved: this.entity.isRemoved,
        isConfirmed: this.entity.isConfirmed,
      };
      this.loading = true;
      const res = await regionsApi.createRegion(this.resource, entity);
      this.showInfo("Регион добавлен");
      this.entity.id = res.data;
      NestedEditorsEventBus.$emit("create", this.entity.id);
      this.loading = false;
    },
    cancel() {
      NestedEditorsEventBus.$emit("close");
    },
    async saveAndClose() {
      await this.submit();
    },
    resetLoaders() {
      this.loading = false;
    },
  },
};
</script>
