<template>
  <v-card class="pa-2">
    <v-card-title class="pb-2">
      <h1 class="headline">
        Редактирование Продавца
      </h1>
    </v-card-title>
    <v-card-text class="pt-2">
      <v-form v-model="isValid">
        <v-layout column>
          <v-flex xs12>
            <v-layout row>
              <v-flex xs6>
                <TitleTextField
                  v-model="title"
                  :required="true"
                  :rules="[rules.required, rules.min]"
                  label="Наименование*"
                  class="mx-2 mb-2"
                  @input="isChanged = true"
                />
              </v-flex>
              <v-flex xs6>
                <MultipleReferenceCompact
                  v-model="contactPersons"
                  class="mx-2 mb-2"
                  resource="contact-persons"
                  :required="true"
                  :rules="[rules.empty]"
                  component-name="ContactPerson"
                  label="Контактное лицо*"
                  @input="isChanged = true"
                />
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex xs6>
                <EditableList
                  v-model="phones"
                  class="mx-2 mb-4"
                  label="Телефон"
                  :mask="getMask('phone')"
                  :hint="hint"
                  filter="phoneInternational"
                  title="Редактирование номеров телефонов"
                  data-test="phone"
                  @change="changePhones"
                />
              </v-flex>
              <v-flex xs6>
                <EditableList
                  v-model="emails"
                  class="mx-2 mb-4"
                  label="Email"
                  :rules="[rules.email]"
                  title="Редактирование e-mail"
                  data-test="email"
                  @change="changeEmails"
                />
              </v-flex>
            </v-layout>

            <v-layout row>
              <v-flex xs12>
                <v-text-field
                  v-model="notes"
                  class="mx-2"
                  label="Специфика"
                  outlined
                  dense
                  @change="isChanged = true"
                />
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>

        <v-layout
          row
          class="mt-3"
        >
          <v-flex
            xs8
            class="mr-3"
          >
            <v-layout column>
              <v-flex xs4>
                <LoadingPlaces
                  v-model="loadingPlaces"
                  :items="loadingPlaces"
                  @create="isChanged = true"
                  @change="isChanged = true"
                  @delete="isChanged = true"
                  @cancel="cancelChangeLoadingPlace"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs3>
            <v-card min-height="100%">
              <v-card-title>
                <h2 class="subtitle-1 font-weight-bold">
                  Документы
                  <span
                    v-if="attachments.length > 0"
                    class="blue--text text--darken-4"
                  >({{ attachments.length }})</span>
                </h2>
              </v-card-title>
              <Attachments
                v-model="attachments"
                :disabled-upload-to-server="isNew"
                :disabled-upload="isNew"
                :disabled-sender="isNew"
                :disabled-download="isNew"
              />
            </v-card>
          </v-flex>
        </v-layout>

        <v-layout class="mt-3">
          <v-btn
            small
            color="primary"
            :disabled="!(isValid && isChanged) || isRemoved || loading"
            data-test="saveButton"
            class="mt-3"
            @click="submit(false)"
          >
            Сохранить
          </v-btn>
          <v-btn
            small
            color="primary"
            class="mt-3 mx-3"
            :disabled="!(isValid && isChanged) || isRemoved || loading"
            data-test="saveAndCloseButton"
            @click="saveAndClose"
          >
            Сохранить и закрыть
          </v-btn>
          <v-btn
            small
            data-test="cancelButton"
            class="mt-3"
            @click="cancel"
          >
            Отмена
          </v-btn>
        </v-layout>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { NestedEditorsEventBus } from "../../components/nested-editors/nested-editors-event-bus.js";
import ConfirmMixin from "../../mixins/confirm-cancel-changes.js";
import Attachments from "../../components/attachments/Attachments.vue";
import LoadingPlaces from "./LoadingPlaces.vue";
import TitleTextField from "../../components/TitleTextField.vue";
import MultipleReferenceCompact from "../../components/MultipleReferenceCompact.vue";
import EditableList from "../../components/EditableList.vue";
import * as factoriesApi from "../../backend/factoriesApi.js";

export default {
  components: {
    Attachments,
    LoadingPlaces,
    TitleTextField,
    MultipleReferenceCompact,
    EditableList,
  },
  mixins: [ConfirmMixin],
  data() {
    return {
      isValid: false,
      isChanged: false,
      rules: {
        empty: v => !!v || "",
        required: v => !!v || "Поле обязательно к заполнению",
        min: v =>
          v.length >= 3 || "Поле должно содержать не менее 3-х символов",
        email: v => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(v) || v === "" || "Не корректно введен e-mail.";
        },
      },
      resource: "factories",
      addMessage: "Продавец добавлен",
      editMessage: "Продавец изменен",
      title: "",
      phones: [],
      emails: [],
      notes: "",
      contactPersons: [],
      loadingPlaces: [],
      attachments: [],
      isRemoved: false,
      hint: "Номер телефона уже добавлен",
      loading: false,
    };
  },
  computed: {
    isModal() {
      let parent = this.$parent;
      while (parent) {
        if (parent.$options._componentTag === "NestedEditors") {
          return true;
        }
        parent = parent.$parent;
      }
      return false;
    },
    isNew() {
      return true;
    },
  },
  mounted() {
    if (this.isModal) {
      NestedEditorsEventBus.$emit("modal-editor-mounted");
    }
  },
  methods: {
    changePhones(val) {
      this.isChanged = true;
      this.phones = val;
    },
    changeEmails(val) {
      this.isChanged = true;
      this.emails = val;
    },
    getMask(controlType) {
      switch (controlType) {
        case "phone":
          return "+##(###)###-##-##";
        default:
          return "";
      }
    },
    async submit() {
      const loadingPlaces = [];
      this.loadingPlaces.forEach(lp => {
        loadingPlaces.push({
          id: lp.id,
          addressId: lp.address.id,
          timetable: lp.timetable,
          contactPersonsIds: lp.contactPersons
            ? lp.contactPersons.map(cp => cp.id)
            : [],
        });
      });

      const factory = {
        title: this.title,
        phones: this.phones,
        emails: this.emails,
        notes: this.notes,
        contactPersonsIds: this.contactPersons.map(i => i.id),
        factoryLoadingPlaces: loadingPlaces,
      };
      this.loading = true;
      const res = await factoriesApi.createFactory(this.resource, factory);
      this.saveAttachments(res.data);
      this.showInfo(this.addMessage);
      this.isChanged = false;
      this.loading = false;
    },
    cancel() {
      NestedEditorsEventBus.$emit("close");
    },
    async saveAndClose() {
      await this.submit();
    },
    cancelChangeLoadingPlace() {
      this.isChanged = true;
    },
    saveAttachments(id) {
      factoriesApi.saveFactoryAttachments(
        this.resource,
        id,
        this.attachments,
      );
      NestedEditorsEventBus.$emit("create", id);
    },
    resetLoaders() {
      this.loading = false;
    },
  },
};
</script>
