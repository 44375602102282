<template>
  <v-layout column>
    <v-toolbar
      color="white"
      text
      flat
      height="40"
    >
      <h2
        v-if="title"
        class="body-2 font-weight-bold"
      >
        {{ title }}
      </h2>
      <v-spacer />
      <FileUploader
        :disabled="disabled"
        :resource="resource + '/attachments'"
        :disabled-sender="disabledSender"
        :disabled-upload="disabledUpload"
        :disabled-upload-to-server="disabledUploadToServer"
        :type="type"
        :client-id="clientId"
        @upload="add"
      />
    </v-toolbar>
    <div :style="{ height: blockHeight + 'px', 'overflow-y': 'auto' }">
      <v-list
        v-if="attachments.length"
        class="pt-0"
      >
        <v-list-item
          v-for="item in attachments"
          :key="disabledUploadToServer ? item.sourceName : item.id"
          class="compact"
        >
          <v-list-item-content>
            <v-list-item-title
              v-if="edit !== item"
              height="44"
              v-text="item.title"
            />
            <v-layout v-else>
              <v-text-field
                v-model="item.title"
                hide-details
                label="Название файла"
                outline
                dense
              />
              <v-btn
                :loading="changeNameLoading"
                icon
                @click="applyEdit(item)"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-layout>
            <div>
              <span style="color: rgba(0,0,0,.54);">{{ item.author }}: {{ item.createdAt | dateTime }} </span>
            </div>
          </v-list-item-content>
          <v-list-item-action>
            <v-layout>
              <v-btn
                v-if="!disabledDownload"
                class="ma-0"
                small
                icon
                @click="download(item.id)"
              >
                <v-icon color="grey darken-1">
                  mdi-download
                </v-icon>
              </v-btn>
              <v-btn
                v-if="!clientId"
                class="ma-0"
                :disabled="disabled"
                small
                icon
                @click="startEdit(item)"
              >
                <v-icon color="grey darken-1">
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                v-if="!clientId"
                class="ma-0"
                :disabled="disabled"
                small
                icon
                @click="remove(item)"
              >
                <v-icon color="grey darken-1">
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-layout>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <h3
        v-else
        class="caption"
      >
        Нет данных
      </h3>
    </div>
  </v-layout>
</template>

<script>
import FileUploader from "./FileUploader.vue";
import * as attachmentsApi from "../../backend/attachmentsApi.js";

export default {
  components: {
    FileUploader,
  },
  props: [
    "value",
    "disabled",
    "resource",
    "allowRemove",
    "disabledSender",
    "disabledUpload",
    "disabledUploadToServer",
    "disabledDownload",
    "blockHeight",
    "type",
    "title",
    "clientId",
    "draftId",
  ],
  data() {
    return {
      attachments: [],
      edit: null,
      changeNameLoading: false,
      fileExtention: "",
    };
  },
  watch: {
    value: {
      handler() {
        this.attachments = this.value.length ? this.value : [];
      },
      immediate: true,
    },
  },
  methods: {
    add(newAttachments) {
      this.attachments = this.attachments.concat(newAttachments);
      this.$emit("input", this.attachments);
      this.$emit("change");
    },
    async download(id) {
      const res = await attachmentsApi.getAttachment(id, this.clientId);
      const url = window.URL.createObjectURL(new Blob([res.data]));

      const a = document.createElement("a");
      a.href = url;
      a.style = "display: none";
      const index = this.attachments.map(item => item.id).indexOf(id);
      a.download = this.attachments[index].title;

      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
    },
    startEdit(item) {
      this.attachments.forEach(file => {
        if (file.id === item.id) {
          const splitTitle = file.title.split(".");
          this.fileExtention = splitTitle[splitTitle.length - 1];
        }
      });
      this.edit = item;
    },
    async applyEdit(item) {
      this.changeNameLoading = true;
      if (this.disabledUploadToServer) {
        this.changeNameLoading = false;
        this.attachments.forEach((file, index) => {
          if (index === this.attachments.indexOf(item)) {
            const newTitle = this.edit.title.includes(this.fileExtention)
              ? this.edit.title
              : `${this.edit.title}.${this.fileExtention}`;
            file.title = newTitle;
            file.sourceName = newTitle;
          }
        });
        this.$emit("input", this.attachments);
        this.edit = null;
      } else {
        this.edit.title = this.edit.title.includes(this.fileExtention)
          ? this.edit.title
          : `${this.edit.title}.${this.fileExtention}`;

        await attachmentsApi.updateAttachment(this.draftId, this.edit.id, this.edit.title);
        this.changeNameLoading = false;
        this.edit = null;
        this.showInfo("Изменения сохранены");
      }
    },
    async remove(item) {
      if (this.disabledUploadToServer) {
        const index = this.attachments.indexOf(
          this.attachments.find(
            element => element.sourceName === item.sourceName,
          ),
        );
        this.attachments.splice(index, 1);
      } else {

        await attachmentsApi.deleteAttachment(this.draftId, item.id);
        const index = this.attachments.map(item => item.id).indexOf(item.id);
        this.attachments.splice(index, 1);
        this.showInfo("Файл удален");
      }
    },
  },
};
</script>
