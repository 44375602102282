import http from "./http.js";

async function getItems(resource) {
  const response = await http.get(`isg-logistics/${resource}`);
  return response.data.map(i => {
    return {
      id: i.id,
      title: i.title,
      isRemoved: i.isRemoved,
      disabled: false,
    };
  });
}

export { getItems };
