<template>
  <v-autocomplete
    v-model="selected"
    :label="label"
    :items="items"
    item-text="title"
    item-value="id"
    hide-details
    clearable
    multiple
    chips
    deletable-chips
    open-on-clear
    small-chips
    return-object
    hide-selected
    autocomplete="nope"
    no-filter
    no-data-text="Данные не выбраны"
    outlined
    dense
    :disabled="disabled"
    menu-props="closeOnContentClick"
    :rules="rules"
    @change="changeEnum"
  />
</template>

<script>
export default {
  props: [
    "label",
    "value",
    "resource",
    "outline",
    "heightField",
    "disabled",
    "rules",
  ],
  data() {
    return {
      selected: this.value,
    };
  },
  computed: {
    items() {
      return this.$store.getters["enums/items"](this.resource);
    },
  },
  watch: {
    value(newValue) {
      this.selected = newValue;
    },
  },
  async created() {
    await this.$store.dispatch("enums/getItems", this.resource);
  },
  methods: {
    changeEnum() {
      if (this.selected) {
        return this.$emit("input", this.selected);
      } else {
        return this.$emit("input", []);
      }
    },
  },
};
</script>
