<template>
  <v-autocomplete
    v-model="selected"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    :rules="rules"
    :label="label"
    return-object
    hide-details
    hide-selected
    :disabled="disabled"
    autocomplete="nope"
    no-filter
    no-data-text="Данные не выбраны"
    :readonly="readonly"
    :clearable="clearable"
    :class="{'red lighten-4':selected && selected.isRemoved}"
    outlined
    :menu-props="menuPropsClose"
    dense
    prepend-inner-icon="mdi-folder-outline"
    @change="handleItemChange"
    @click="closeMenuPropsOnClick(false)"
  >
    <template v-slot:append>
      <AddReferenceBtn @click="createNew" />
    </template>
  </v-autocomplete>
</template>
<script>
import AddReferenceBtn from "../AddReferenceBtn.vue";
import CloseDropdownList from "../../mixins/close-dropdown-list.js";
import { NestedEditorsEventBus } from "../nested-editors/nested-editors-event-bus.js";
import * as countriesApi from "../../backend/countriesApi";

export default {
  components: { AddReferenceBtn },
  mixins: [CloseDropdownList],
  props: ["value", "label", "rules", "disabled", "readonly", "clearable"],
  data() {
    return {
      selected: null,
      search: null,
      items: [],
      resource: "countries",
      componentName: "Country",
      loading: false,
    };
  },
  watch: {
    value: {
      handler() {
        if (!this.value) {
          this.selected = null;
          this.search = null;
          this.items = [];
          return;
        }
        if (this.selected && this.value.id === this.selected.value) {
          return;
        }
        const selectedItem = this.items.find(i => i.value === this.value.id);
        if (selectedItem) {
          this.selected = selectedItem;
        } else {
          this.setSelectedElement(this.value);
        }
      },
      deep: true,
    },
    search() {
      if (!this.search) {
        this.selected = null;
        this.fetchAll();
        return;
      }

      if (this.loading) {
        return;
      }

      if (this.selected && this.selected.text === this.search) {
        return;
      }

      this.loading = true;
      setTimeout(() => {
        if (!this.search) {
          this.selected = null;
          this.items = [];
          this.loading = false;
          return;
        }
        this.getReferenceSearch();
        this.loading = false;
      }, 500);
    },
  },
  created() {
    if (this.value) {
      this.setSelectedElement(this.value);
    }
    NestedEditorsEventBus.$on("create-" + this._uid, createdId => {
      this.getCountryReference(createdId);
    });
  },
  mounted() {
    this.$watch(
      () => {
        return this.$children[0].$data.isMenuActive;
      },
      newVal => {
        if (newVal) {
          this.fetchAll();
        } else {
          this.clear();
        }
      },
    );
  },
  methods: {
    handleItemChange() {
      this.$emit(
        "input",
        this.selected
          ? {
            id: this.selected.value,
            title: this.selected.text,
            inputLanguage: this.selected.inputLanguage,
          }
          : null,
      );
    },
    async fetchAll() {
      this.items = await countriesApi.fetchCountries(this.resource);
      this.loading = false;
    },
    clear() {
      this.items = [];
      if (this.selected) {
        this.items.push(this.selected);
      }
    },
    createNew() {
      this.closeMenuPropsOnClick(true);
      this.$emit("click");
      NestedEditorsEventBus.$emit("open", {
        title: this.label,
        uid: this._uid,
        component: this.componentName,
      });
    },
    setSelectedElement(value) {
      const newItem = {
        text: value.title,
        value: value.id,
        inputLanguage: value.inputLanguage,
        isRemoved: value.isRemoved,
      };
      this.items.push(newItem);
      this.selected = newItem;
    },
    async getCountryReference(createdId) {
      const res = await countriesApi.getCountryReference(
        this.resource,
        createdId,
      );
      this.setSelectedElement(res);
      this.handleItemChange();
    },
    async getReferenceSearch() {
      const response = await countriesApi.searchReferencies(
        this.resource,
        this.search,
      );
      if (Array.isArray(response)) {
        this.items = response;
      } else {
        this.items = [];
      }
    },
  },
};
</script>
