<template>
  <v-container
    fluid
    class="ma-0 pa-0"
  >
    <v-row
      v-if="loggedIn"
      no-gutters
    >
      <v-col>
        <AppHeader>
          <template v-slot:logout>
            <v-col class="pa-0">
              <v-btn
                icon
                color="white"
                @click="logout"
              >
                <v-icon>mdi-logout</v-icon>
              </v-btn>
            </v-col>
          </template>
        </AppHeader>
      </v-col>
    </v-row>
    <v-row
      v-if="loggedIn && !isAdmin"
      class="ma-1 pa-0"
    >
      <v-col
        class="pa-0"
      >
        <InfoMenu />
      </v-col>
    </v-row>
    <v-row class="ma-1 pa-0">
      <v-col class="pa-0">
        <router-view :key="$route.fullPath" />
        <NestedEditors />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import InfoMenu from "@/components/InfoMenu.vue";
import auth from "@/auth.js";
import NestedEditors from "../../components/nested-editors/NestedEditors.vue";

export default {
  components: {
    AppHeader,
    InfoMenu,
    NestedEditors,
  },
  data() {
    return {
      loggedIn: false,
    };
  },
  computed: {
    isAdmin() {
      return auth.isAdmin();
    },
  },
  async updated() {
    const user = await auth.getUser();
    this.loggedIn = !!user;
  },
  methods: {
    logout() {
      auth.logout();
      location.reload();
      window.location = "/";
    },
  },
};
</script>
